import React from 'react';
import Loader from '../spinner/Loader';
import { Link, useNavigate } from 'react-router-dom';
import styles from './CartoonListItem.module.css';
import { LanguageContext } from '../../context/LanguageContext';
import CategoryCards from '../card/cardBestsellers/category/CategoryCards';
import { nanoid } from 'nanoid';

const CartoonListItem = (props) => {
  const {
    categories,
    cartoonCategories,
    isFirstItem,
    length,
    selectedIndex,
    isPlaylist,
    showId,
    id,
    setShowId,
    setCheckedCartoonId,
    removeFromPlaylists,
    setShowAddToPlaylist,
    playlistId,
    isSelected,
    index,
    imageUrl,
    duration,
    title,
  } = props;
  const navigate = useNavigate()
  let classes = `${styles.main_h} ${styles.cursor_pointer} ${styles.item} ${styles.relative} d-flex mr-5 bg-red align-items-center text-decoration-none`;
  let itemStyle = isFirstItem ? `${classes} ${styles.margin_top_10}` : classes;
  let top =
    selectedIndex === length
      ? length > 1
        ? `${styles.top_last}`
        : `${styles.center}`
      : `${styles.top}`;
  let imageDivClasses = isPlaylist
    ? `${styles.image} ${styles.position_relative} overflow-hidden`
    : `${styles.smiliar_image} ${styles.position_relative} overflow-hidden`;

  const showAlert = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (showId === id) {
      setShowId(null);
      setCheckedCartoonId('');
    } else {
      setShowId(id);
      setCheckedCartoonId(id);
    }
  };

  const playListActions = (index) => async () =>{
    setShowId(null);
    index === 0 ? setShowAddToPlaylist(true) : removeFromPlaylists(id);
  }

  const navigateToCartoon = () => {
    const thumb = document.getElementById('thumb')
    const progress = document.getElementById('progress')
    progress.style.width = '0%'
    thumb.style.left = '0%'

    navigate(playlistId !== ''
    ? `/cartoons/${playlistId}/${id}`
    : `/cartoons/${id}`)
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <Link
            onClick={navigateToCartoon}
            state={{ play: true }}
            className={
              isSelected
                ? `${itemStyle} ${styles.selected} ${styles.relative}`
                : itemStyle
            }
            id={isPlaylist ? `playlist_${index}` : `${index}`}
          >
            {isPlaylist && isSelected ? (
              <img
                src='../../assets/cartoon/video/playlistPlay.png'
                className={styles.next}
                alt='play'
              />
            ) : null}
            <div className={imageDivClasses} id={`img_${id}`}>
              {imageUrl ? (
                <>
                  <img
                    src={`${process.env.REACT_APP_DEV_URI}${imageUrl}`}
                    className={styles.img}
                    alt='cartoon'
                  />
                  <p className={`${styles.duration} ${styles.font_bold}`}>
                    {duration}
                  </p>
                </>
              ) : (
                <div className={styles.loader}>
                  <Loader />
                </div>
              )}
            </div>
            <div className={`${styles.description} overflow-hidden`}>
              <div
                className={`${styles.font_bold} ${styles.line_height_24} ${styles.cartoon_title} mb-0 ${styles.padding_right_20} overflow-hidden`}
              >
                {title}
              </div>
              <div
                className={`${styles.font_size_13} ${styles.cartoon_category} overflow-hidden d-block`}
              >
                <CategoryCards
                  data={cartoonCategories}
                  categories={categories}
                  image={true}
                  category={true}
                />
              </div>
            </div>
            {isPlaylist ? (
              <div
                className={`${styles.trash} d-flex justify-content-center`}
                onClick={showAlert}
              >
                <img
                  src='../../assets/cartoon/card/cartoonMenu.svg'
                  alt='trash'
                />
              </div>
            ) : null}
            {showId === id ? (
              <div className={`${top} ${styles.playlist_modal} d-flex justify-content-center flex-column`}>
                {['save_to_playlist', 'remove_from_playlist'].map(
                  (item, index) => {
                    return (
                      <div
                      key={`${item}_${nanoid()}`}
                        onClick={playListActions(index)}
                        className={`${styles.playlist_modal_item} ${styles.mb_5} d-flex align-items-center`}
                      >
                        <img
                          src={`../../assets/video/${item}.svg`}
                          alt='Playlist'
                        />
                        <span className='overflow-hidden text-nowrap'>
                          {ctx?.data && ctx?.data[item][ctx.language]}
                        </span>
                      </div>
                    );
                  }
                )}
              </div>
            ) : null}
          </Link>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CartoonListItem;
