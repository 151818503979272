import classes from './FilterItems.module.css';
import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../components/checkbox/Checkbox';
import { LanguageContext } from '../../../../context/LanguageContext';
import FilterItem from '../../../../components/filter/filterItem/FilterItem';
import { filterItemsData } from '../../../../utils/helpers/filterItemsData/filterItemsData';

const FilterItems = (props) => {
  const {
    onChange,
    checkedData,
    defaultCategoryToggled,
    filterShow,
    categories,
    authors,
    age,
    booksType,
    ratings,
    cartoons,
  } = props;

  const [show, setShow] = useState([]);
  const keys = filterItemsData.checkKeys(cartoons);
  const [hoveredRating, setHoveredRating] = useState(null);
  const data = JSON.parse(JSON.stringify({categories, authors, age, type: booksType, rating: ratings}))

  const onMouseEnter = (id) => {
    setHoveredRating(id);
  };

  useEffect(() => {
    setShow(prevState => [...prevState, defaultCategoryToggled]);
  }, [defaultCategoryToggled]);

  const handleClick = (item) => {
    let openedItems = [...show];
    if (openedItems.includes(item)) {
      openedItems = openedItems.filter((key) => key !== item);
    } else {
      openedItems.push(item);
    }
    setShow(openedItems);
  };

  if (!categories || categories.length === 0) return;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            {Object.keys(data)?.map((title, index) => {
              return (
                <FilterItem
                  key={`title_${title}`}
                  showData={show?.includes(title)}
                  onClick={() => handleClick(title)}
                  title={
                    ctx.data && ctx.data[title] && ctx.data[title][ctx.language]
                  }
                  showFilter={
                    filterShow?.includes('filter') || window.innerWidth >= 980
                  }
                >
                  {show.includes(title) &&
                    data[title]?.map((item) => {
                      return (
                        <Checkbox
                          key={`filter_${item._id ?? item}`}
                          type='checkbox'
                          hidden={title !== 'rating' ? false : true}
                          onMouseEnter={() => onMouseEnter(item.starId)}
                          onMouseLeave={() => onMouseEnter(!item.starId)}
                          name={title === 'rating' ? 'stars' : ''}
                          img={item.imageUrl}
                          id={item._id ?? item}
                          value={title === 'age' ? item : item._id ?? item}
                          title={
                            title === 'rating'
                              ? ''
                              : title === 'age'
                              ? item
                              : title === 'type'
                              ? item.name
                              : item.name[ctx.language]
                          }
                          onChange={onChange(keys[index])}
                          divClassName={
                            title === 'rating'
                              ? `${classes.selected_rating_div} ${
                                  checkedData['rating']?.includes(item.starId)
                                    ? classes.selected_rating_div_active
                                    : ''
                                } d-flex align-items-center justify-content-center position-relative`
                              : ''
                          }
                          labelClassName={
                            title === 'rating' &&
                            `${
                              checkedData['rating'] === item.starId
                                ? `${classes.active_star} position-absolute`
                                : !item.starId
                                ? 'position-absolute'
                                : ''
                            } ${
                              hoveredRating === item.starId &&
                              `${classes.active_star} position-absolute`
                            }`
                          }
                          checkedData={checkedData[keys[index]]?.includes(
                            title === 'age' ? item : item._id
                          )}
                        />
                      );
                    })}
                </FilterItem>
              );
            })}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default FilterItems;
