import React from "react";
import classes from './AddToPlaylist.module.css'

const AddPlayList = (props) => {
const {playlist, text, save, addToPlaylistOnClick} = props

    return (
        <>
        {playlist !== 'true'  &&
            <div className={`d-flex w-100 justify-content-between`}>
              <div>{save}</div>
              <div
                className={`${classes.cursor_pointer} ${classes.add_new} d-flex flex-column`}
                onClick={addToPlaylistOnClick}
              >
                {text}
              </div>
            </div>
          }
          </>
    )
}

export default AddPlayList