import React from 'react';
import Modals from '../Modals';
import ModalTitles from '../modalTitles/ModalTitles';
import classes from './ConfirmationModal.module.css';
import ConfirmationButtons from './buttons/ConfirmationButtons';
import { LanguageContext } from '../../../context/LanguageContext';

const ConfirmationModal = (props) => {
  const { show, onHide, logOutHandler, titles } = props;

  const confirmationHandler = (type) => {
    type ? onHide() : logOutHandler();
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <Modals show={show} onHide={onHide}>
            <div
              className={`${classes.confirm_logOut_container} d-flex justify-content-center flex-wrap w-100`}
            >
              <div className={`${classes.confirm_logOut} d-flex flex-wrap`}>
                <div
                  className={`${classes.confirm_logOut_container} d-flex justify-content-center flex-wrap w-100`}
                >
                  {Object.keys(titles).map((title) => {
                    return (
                      <div
                        className={
                          !['minTitle', 'errorMessage'].includes(title)
                            ? `${classes.logOut_text_container} d-flex text-center justify-content-center`
                            : ''
                        }
                        key={`confirm_verification_code_${title}`}
                      >
                        <ModalTitles
                          {...{
                            [title]: titles[title],
                            classIdentifier: title,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <ConfirmationButtons
                  title={{
                    confirm: ctx.data?.yes[ctx.language],
                    disapprove: ctx.data?.no[ctx.language],
                  }}
                  confirmationHandler={(type) => {
                    confirmationHandler(type);
                  }}
                  onHide={onHide}
                />
              </div>
            </div>
          </Modals>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default ConfirmationModal;
