
import { useEffect } from 'react';
import React, { useState } from 'react';
import Spacing from './spacing/Spacing';
import DarkMode from './darkMode/DarkMode';
import Advanced from './advanced/Advanced';
import classes from './TextSettings.module.css';
import ScrollMode from './scrollMode/ScrollMode';
import FontSizeConfiguration from './fontSizeConfiguration/FontSizeConfiguration';
import FontFamilyConfiguration from './fontFamilyConfiguration/FontFamilyConfiguration';

const TextSettings = (props) => {
  const [value, setValue] = useState();
  const [advanced, setAdvanced] = useState(false);

  const {
    decreaseFontSize,
     increaseFontSize,
    isSwitch,
    scrollHandler,
     brightnessModeHandler,
     fontType,
     textSpacing,
     setTextSpacing,
     addSpacing,
     changeFontSize
  } = props;

  const fontFamilyHandler = (fontType) => {
    setValue(fontType);
  };

  useEffect(() => {
    fontType(value);
  }, [props, value, fontType]);

  const prevToSettings = () => {
    setAdvanced(false)
  }

        return (
          <div className='d-flex justify-content-center flex-wrap'>
            {!advanced ? (
              <>
                 <FontSizeConfiguration
                   decreaseFontSize={decreaseFontSize}
                    increaseFontSize={increaseFontSize}
                    changeFontSize={changeFontSize}
                />
                <FontFamilyConfiguration fontFamilyHandler={fontFamilyHandler} />
                <ScrollMode isSwitch={isSwitch} scrollHandler={scrollHandler}/>
                <div className={`${classes.data_container} d-flex flex-wrap`}>
                  <DarkMode
                    brightnessModeHandler={brightnessModeHandler}
                  />
                  <Advanced setAdvanced={setAdvanced} />
                </div>
              </>
            ) : (
              <Spacing prevToSettings={prevToSettings} textSpacing={textSpacing} setTextSpacing={setTextSpacing} addSpacing={addSpacing}/>
            )}
          </div>
  );
};

export default TextSettings;
