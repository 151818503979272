import React from 'react';
import classes from './UploadCartoonsItem.module.css';
import UploadCartoonsTab from './uploadCartoonsTab/UploadCartoonsTab';
import UploadCartoonInput from './uploadCartoonInput/UploadCartoonInput';
import UploadCartoonTitle from './uploadCartoonTitle/UploadCartoonTitle';

const UploadCartoonsItem = (props) => {
  const {
    changeLanguage,
    name,
    languageHandler,
    title,
    placeholder,
    setShow,
    show,
    onMouseEnter,
    onMouseLeave,
    isDescription,
    selectedLanguage,
    textInputProps,
    cartoonData,
    keys,
    cartoonId
  } = props;

  return (
    <div className={`${classes.gap_10} flex-wrap d-flex w-100 position-relative`}>
      <UploadCartoonTitle
        title={title}
        setShow={setShow}
        show={show}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <div
        className={`${classes.uploadCartoonData} ${classes.gap_5} d-flex w-100 flex-wrap`}
      >
        <UploadCartoonsTab
          selectedLanguage={selectedLanguage}
          languageHandler={(type) => changeLanguage(type)}
          textInputProps={textInputProps}
        />
        {process.env.REACT_APP_LANGUAGES.split(', ').map(lang => {
        if((cartoonId && selectedLanguage === lang && Object.keys(name).length > 0) || (!cartoonId && selectedLanguage === lang)) {
            return (
            <UploadCartoonInput
            key={textInputProps?.labelIds[lang]}
            placeholder={placeholder}
            onChange={languageHandler(lang)}
            defaultValue={cartoonData && cartoonData[keys] ? cartoonData[keys][lang] : name[lang] ?? ''}
            isDescription={isDescription}
            id={textInputProps?.labelIds[lang]}
            first={textInputProps?.labelIds[lang] === 'labelNameAm'}
          />
            )
          } else return null
        })
        }
      </div>
    </div>
  );
};

export default UploadCartoonsItem;
