import classes from './UserAgeGroup.module.css';
import { useDispatch, useSelector } from 'react-redux';
import ageGroups from '../../../store/ageGroups/AgeGroups';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../context/user/UserContext';
import { LanguageContext } from '../../../context/LanguageContext';
import fetchPreferance from '../../../store/edit/preference/FetchPreference';
import ComponentsTitle from '../userAccountTitle/componentsTitle/ComponentsTitle';
import AgeGroup from '../../../components/user/userAccountComponent/ageGroup/AgeGroup';
import ChangeDataButtons from '../../../components/user/editData/changeDataButtons/ChangeDataButtons';
import UserAccountComponent from '../../../components/user/userAccountComponent/UserAccountComponent';

const UserAgeGroup = (props) => {
  const {setData, data, userAgeGroup, categoryData} = props
  const dispatch = useDispatch();
  const [ageData, setAgeData] = useState([]);
  const [ageGroup, setAgeGroup] = useState(false);
  const { user, setUserData } = useContext(UserContext);
  const ageGroupData = useSelector((state) => state.ageGroups.ageGroups.data);

  useEffect(() => {
    dispatch(ageGroups());
  }, [dispatch]);

  useEffect(() => {
    setAgeGroup(data === 'ageGroup')
  }, [data])

  const cancel = (e) => {
    e.preventDefault();
    setAgeGroup(false);
    setAgeData(user.preference?.ageGroup);
  };

  useEffect(() => {
    if (user.preference) {
      setAgeData(user.preference?.ageGroup);
    }
  }, [user, data]);

  const changeAgeGroup = (e) => {
    if (e.target.checked && !ageData?.includes(e.target.value)) {
      setAgeData([...ageData, e.target.value]);
    } else {
      const deleteAge = ageData.filter((data) => data !== e.target.value);
      setAgeData(deleteAge);
    }
  };

  useEffect(() => {
    userAgeGroup(ageData);
  }, [props, ageData, userAgeGroup]);

  const ageGroupHandler = async (e) => {
    e.preventDefault();
    let mutableUser = { ...user };

    const response = await dispatch(
      fetchPreferance(ageData, categoryData)
    );

    if (response.payload.preferance.status === 200) {
      let userPreferences = JSON.parse(JSON.stringify(mutableUser.preference));
      userPreferences.ageGroup = ageData;
      mutableUser['preference'] = userPreferences;
      setUserData(mutableUser);
      setAgeGroup(false);
    }
  };

  const showData = () => {
    setAgeGroup(true);
    setData('ageGroup')
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <ComponentsTitle title={ctx.data?.age_group[ctx.language]} />
            <form
              method='post'
              onSubmit={ageGroupHandler}
              className={`${classes.gap_20} flex-wrap w-100 d-flex`}
            >
              <UserAccountComponent
                action={ctx.data?.edit[ctx.language]}
                titleAge={true}
              >
                <AgeGroup
                  onSubmit={ageGroupHandler}
                  ageGroup={ageGroupData}
                  onChangeaAge={changeAgeGroup}
                  data={ageData}
                  onClick={showData}
                />
              </UserAccountComponent>
              {ageGroup ? (
                <ChangeDataButtons cancel={cancel} />
              ) : null}
            </form>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UserAgeGroup;
