import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PrevButton from './auth/PrevButton';
import classes from './Modals.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddPlayList from './addPlayList/AddPlayList'

const Modals = (props) => {
  const {onHide} = props
  const {prevButtonClick, id, image, children, login, playlist, text, save, addToPlaylistOnClick, ...rest} = props
  document.body.style.paddingRight = '0 !important'

  return (
    <Modal
      {...rest}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      id={id}
    >
      <div className='modalHeaderContainer' id='defaultModal'>
        <Modal.Header closeButton>
          {login ? (
            <PrevButton image={image} prevButtonClick={prevButtonClick} />
          ) : null}
          <AddPlayList playlist={playlist} text={text} save={save} addToPlaylistOnClick={addToPlaylistOnClick}/>
          {playlist === undefined && <div className={`d-flex justify-content-end w-100`}>
            <button className={`${classes.cancel_button} border-0`} onClick={onHide}>
              <img className='w-100' src='../../assets/icons/cancel/cancelModal.svg' alt='' />
            </button>
          </div>}
        </Modal.Header>
      </div>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default Modals;
