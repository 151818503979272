import Cookies from 'js-cookie';
import classes from './MyBooks.module.css';
import MyBooksTab from './tabs/MyBooksTab';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/spinner/Loader';
import NoData from '../../../components/noData/NoData';
import { UserContext } from '../../../context/user/UserContext';
import PageTitle from '../../../components/pageTitle/PageTitle';
import { LanguageContext } from '../../../context/LanguageContext';
import { constantData } from '../../../utils/helpers/constantData';
import MyBooksCard from '../../../components/card/myBooks/MyBooksCard';
import PageinationData from '../../../components/pageination/Pageination';
import productCategories from '../../../store/category/ProductCategories';
import readedBooks from '../../../store/readBooks/readedBooks/ReadedBooks';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import deleteReadBook from '../../../store/readBooks/deleteReadBook/DeleteReadBook';
import deletePurchases from '../../../store/purchases/deletePurchases/DeletePurchases';
import purchasedProducts from '../../../store/purchases/purchasedProducts/PurchasedProducts';
import favoriteProducts from '../../../store/favoriteProducts/allFavoriteProducts/favoriteProducts';
import deleteFavoriteProduct from '../../../store/favoriteProducts/deleteFavoriteProduct/DeleteFavoriteProduct';

const MyBooks = () => {
  const limit = process.env.REACT_APP_MY_BOOKS_LIMIT;
  const dispatch = useDispatch();
  const token = Cookies.get('auth_token');
  const [pageCount, setPageCount] = useState();
  const data = sessionStorage.getItem('booksData');
  const pageData = sessionStorage.getItem('myBooksActiveButton');
  const [activePage, setActivePage] = useState(+pageData | 1);
  const [booksData, setBooksData] = useState(!data || data === 'undefind' ? JSON.parse(data) : []);
  const categories = useSelector((state) => state.categories.categories);
  const { user, setUserData } = useContext(UserContext);
  const [showLoader, setShowLoader] = useState(true);
  const [activeTab, setActiveTab] = useState(
    +sessionStorage.getItem('activeTab') ?? 0
  );
  useEffect(() => {
    logEvent(firebaseAnalytics, 'web_app_loaded')
    dispatch(productCategories());
  }, [dispatch, token, activePage, limit]);

  const tabsHandler = useCallback(
    async (id, page) => {
      setShowLoader(true)
      const requestTypes = [purchasedProducts, readedBooks, favoriteProducts];
      if (page > 0) {
        setActivePage(page);
      }
      const response = await dispatch(requestTypes[id](limit, activePage));
      setShowLoader(false)
      setBooksData(response.payload?.data);
      setActiveTab(id);
      setPageCount(response.payload?.pageCount);
      response.payload?.data && sessionStorage.setItem(
        'booksData',
        JSON.stringify(response.payload?.data)
      );
      sessionStorage.setItem('activeTab', id);
    },
    [activePage, dispatch, limit]
  );

  useEffect(() => {
    tabsHandler(activeTab)
  }, [activePage, activeTab, tabsHandler])

  const booksDeleteHandler = async (productId) => {
    const productsInUserModel = ['purchases', 'read', 'wantToRead'];
    const mutableData = { ...user };
    const requestTypes = [
      deletePurchases,
      deleteReadBook,
      deleteFavoriteProduct,
    ];
    const response = await dispatch(requestTypes[activeTab](productId));
    if (response.payload.status === 200) {
      if (booksData.length === 1 && activePage !== 1) {
        tabsHandler(activeTab, activePage - 1);
      } else {
        tabsHandler(activeTab);
      }
      const filteredData = booksData.filter((item) =>
        response.payload.data
          .map((product) => {
            if (
              (Object.keys(product).includes('inUserHistory') &&
                product.inUserHistory) ||
              !Object.keys(product).includes('inUserHistory')
            ) {
              return product.product;
            } else return false;
          })
          .includes(item.product._id)
      );
      if (productsInUserModel[activeTab] === 'purchases') {
        const product = {
          ...mutableData[productsInUserModel[activeTab]].find(
            (item) => item.product === productId
          ),
        };
        product.inUserHistory = false;
        const products = [
          ...mutableData[productsInUserModel[activeTab]].filter(
            (item) => item.product !== productId
          ),
          product,
        ];
        mutableData[productsInUserModel[activeTab]] = products;
      } else if (productsInUserModel[activeTab] === 'wantToRead') {
        mutableData[productsInUserModel[activeTab]] = mutableData[
          productsInUserModel[activeTab]
        ].filter((item) => item.product !== productId);
      }
      setBooksData(filteredData);
      setUserData(mutableData);
    }
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div className={`${classes.my_books_container} d-flex flex-wrap justify-content-center`}>
              <PageTitle title={ctx.data?.purchased[ctx.language]} />
              <div className={`${classes.my_books} d-flex w-100 justify-content-center`}>
                <div className={`${classes.my_books_item_container} d-flex justify-content-start flex-wrap`}>
                  <MyBooksTab
                    activeTab={activeTab}
                    onClick={tabsHandler}
                    setActivePage={setActivePage}
                    setActiveTab={setActiveTab}
                    keys={constantData.keys}
                    pageName='activeTab'
                    defaultClasses={`${classes.tabbar_small} flex-direction-row`}
                    fs={classes.fs_14}
                  />
                  {booksData?.length > 0 &&
                    booksData?.map((product) => {
                      return (
                        <MyBooksCard
                          deleteBook={() =>
                            booksDeleteHandler(product.product._id)
                          }
                          data={
                            typeof product.product === 'object'
                              ? product.product
                              : {}
                          }
                          categories={categories}
                          key={product.product?._id}
                        />
                      );
                    })}
                  {booksData?.length === 0 && activePage === 1 && (
                    <div className='w-100 d-flex justify-content-center'>
                      <NoData
                        title={ctx.data?.no_books_added_yet[ctx.language]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {pageCount > 1 ? (
              <PageinationData
                myBook={true}
                pageName='myBooksActiveButton'
                activePage={activePage}
                setActivePage={setActivePage}
                pagesCount={pageCount}
              />
            ) : null}
            <div
              className={
                showLoader
                  ? `${classes.abs} ${classes.show} position-fixed top-0 start-0 w-100 d-flex h-100`
                  : `${classes.abs} ${classes.hide} position-fixed top-0 start-0 w-100 d-none`
              }
            >
              <Loader />
            </div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default MyBooks;
