import Cookies from 'js-cookie';
import { useLocation, useParams } from 'react-router-dom';
import BooksData from './booksData/BooksData';
import AuthorData from './authorData/AuthorData';
import React, { useContext, useEffect, useState } from 'react';
import classes from './SingleProductPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import fetchSimiliar from '../../store/similiar/FetchSimiliar';
import SingleProductSlider from './singleProductSlider/SingleProductSlider';
import { UserContext } from '../../context/user/UserContext';
import Loader from '../../components/spinner/Loader';
import deleteFavoriteProduct from '../../store/favoriteProducts/deleteFavoriteProduct/DeleteFavoriteProduct';
import addToFavoriteProducts from '../../store/favoriteProducts/addToFavoriteProducts/AddToFavoriteProducts';
import product from '../../store/singlePage/Product';
import SignInErrorModal from '../../components/modal/error/signInErrorModal/SignInErrorModal';
import fetchProducts from '../../store/products/FetchProducts';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';

const SingleProductPage = () => {
  const productId = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = Cookies.get('auth_token');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user, setUserData } = useContext(UserContext);
  const page = sessionStorage.getItem('activeButton');
  const [addToFavorite, setAddToFavorite] = useState(false);
  const similiar = useSelector((state) => state.similiar.similiar);
  const singleProduct = useSelector((state) => state.singleProduct.singleProduct);
  const [books, setBooks] = useState([])

  useEffect(() => {
    if (location) {
      window.scroll(0, 0);
    }
  }, [location]);

  useEffect(() => {
    if (singleProduct?.author?.books.length > 0 &&  location) {
      setBooks(singleProduct.author.books)
    }
  }, [singleProduct, location])

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);


  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Book_page')
    dispatch(fetchSimiliar(productId.id));
    dispatch(product(productId.id));
  }, [dispatch, page, addToFavorite, productId.id]);

  let inMyFavorites = user.wantToRead?.find(
    (item) => item.product === productId.id
  );

  useEffect(() => {
    if (inMyFavorites) {
      setAddToFavorite(true);
    }
  }, [inMyFavorites]);

  const saveProduct = async () => {
    if (token) {
      let mutableUser = { ...user };
      if (!inMyFavorites) {
        const data = await dispatch(addToFavoriteProducts(productId.id));
        if (data.payload?.status === 200) {
          mutableUser.wantToRead = data.payload.data;
          setUserData(mutableUser);
          setAddToFavorite(true);
        }
      } else {
        const response = await dispatch(deleteFavoriteProduct(productId.id));
        if (response.payload?.status === 200) {
          mutableUser.wantToRead = response.payload.data;
          setUserData(mutableUser);
          setAddToFavorite(false);
        }
      }
    } else {
      setIsAuthorized(true);
    }
  };

  if (Object.keys(singleProduct).length === 0) {
    return <Loader />;
  }

  return (
    <>
      <div className={`${classes.single_product_container} ${classes.gap_50} ${classes.full_width} d-flex flex-wrap w-100`}>
        <div className={`${classes.single_product_container} ${classes.gap_50} ${classes.full_width} d-flex flex-wrap w-100`}>
          <BooksData
            data={singleProduct}
            addToFavorite={addToFavorite}
            onClick={saveProduct}
            id={productId.id}
          />
          <AuthorData data={singleProduct} books={books} />
        </div>
        <SingleProductSlider similiar={similiar} />
      </div>
      <SignInErrorModal
      country={localStorage.getItem('country')}
      setShowErrorModal={setIsAuthorized}
        showErrorModal={isAuthorized}
        onHide={() => {
          setIsAuthorized(false);
        }}
      />
    </>
  );
};

export default SingleProductPage;
