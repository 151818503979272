import React from 'react';
import Cookies from 'js-cookie';
import classes from './CartoonAction.module.css'
import PlaylistModal from '../../components/modal/playlist/PlaylistModal';
import SignInErrorModal from '../modal/error/signInErrorModal/SignInErrorModal';

const CartoonAction = (props) => {
  const token = Cookies.get('auth_token');
  const {
    showAddToPlayList,
    setShowAddToPlaylist,
    showErrorModal,
    setShowErrorModal
  } = props;

  const playlistModalAction = (type) => async () => {
    let video = document.getElementById('video');
    if(type === 'add' && video && video.currentTime >= 0 && !video.ended) {
      !showAddToPlayList ? video.pause() : video.play();
      setShowAddToPlaylist(!showAddToPlayList);
    } else if (type === 'hide' && video) {
      const playButton = document.getElementById('playOrPause')
      video.play();
      playButton.src = '../../assets/video/pause.svg'
      setShowAddToPlaylist(false);
    }
  }

  return (
    <>
      <div className='d-flex ml-auto'>
        {token ? (
          <div className={`d-flex relative ${classes.add_to_playlist}`}>
            <img
              src={'../../../assets/video/addToPlaylists.svg'}
              alt='add'
              onClick={playlistModalAction('add')}
            />
            <PlaylistModal
              show={showAddToPlayList}
              onHide={() => {playlistModalAction('hide'); setShowAddToPlaylist(false)}}
            />
          </div>
        ) : null}
      </div>
      {
        showErrorModal &&
        <SignInErrorModal
          country={localStorage.getItem('country')}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          onHide={() => {
            setShowErrorModal(false);
          }}
        />
      }
    </>
  );
};

export default CartoonAction;