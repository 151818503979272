import Cookies from 'js-cookie';
import classes from './PlayList.module.css'
import React, { useEffect, useState } from 'react';
import NoData from '../../../components/noData/NoData';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import PageTitle from '../../../components/pageTitle/PageTitle';
import { LanguageContext } from '../../../context/LanguageContext';
import PlayListItem from '../../../components/playListItem/PlayListItem';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import fetchAllPlayLists from '../../../store/cartoons/allPlaylist/FetchAllPlayLists';
import SignInErrorModal from '../../../components/modal/error/signInErrorModal/SignInErrorModal';

const PlayList = () => {
  const location = useLocation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const token = Cookies.get('auth_token');
  const limit = process.env.REACT_APP_PLAYLIST_LIMIT;
  const [updatePage, setUpdatePage] = useState(true);
  const [seperatedPlayLists, setSeperatedPlaylists] = useState([]);
  const playLists = useSelector((state) => state.allPlayLists.allPlayLists);
  const [showErrorModal, setShowErrorModal] = useState(typeof token === 'undefined');
  const scrollableDiv = document.getElementsByClassName('infinite-scroll-component__outerdiv')

  if(scrollableDiv && scrollableDiv[0]) {
    scrollableDiv[0].classList.add('d-flex', 'justify-content-center', 'w-100')
  }

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Usre_Playlist');
    dispatch(fetchAllPlayLists(page, limit));
  }, [dispatch, page, limit, location]);

  useEffect(() => {
    if(playLists.allPlayLists.length > 0 && seperatedPlayLists.length === 0) {
      setSeperatedPlaylists(playLists.allPlayLists)
      setUpdatePage(true)
    } else if(playLists.allPlayLists.length !== 0 && playLists.allPlayLists.length > 0 && !updatePage) {
      setSeperatedPlaylists([...seperatedPlayLists, ...playLists.allPlayLists])
      setUpdatePage(true)
    }
  }, [playLists, location])

  const changePage = () => {
    setPage(page + 1);
    setUpdatePage(false);
  };

  const handleScroll = () => {
    if (updatePage) {
      changePage()
    }
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`d-flex justify-content-center w-100 flex-wrap`}>
            <PageTitle
              title={ctx.data?.my_playlists[ctx.language].toUpperCase()}
            />
                <>
                  {!showErrorModal ? (
                    <InfiniteScroll
                    dataLength={seperatedPlayLists.length}
                    next={handleScroll}
                    inverse={false}
                    hasMore={updatePage}
                    className={`justify-content-center d-flex flex-wrap w-100 ${classes.playlist_item_container} ${seperatedPlayLists ? seperatedPlayLists?.length === 0 && 'justify-content-center' : ''}`}// m-5`}
                   >
                    <div className={`d-flex flex-wrap justify-content-start w-100 `}>
                      {seperatedPlayLists?.length > 0 ? (
                        seperatedPlayLists?.map((item, index) => {
                          return (
                            <div
                              className={`d-flex flex-wrap py-1`}
                              key={`elem_${index}`}
                             >
                                  <PlayListItem
                                    key={`playLists_${item._id}`}
                                    playlistId={item._id}
                                    id={item?.cartoons[0] && item?.cartoons[0]}
                                    imageUrl={item.firstImage}
                                    title={item.title}
                                    cartoonCount={item.cartoons?.length}
                                    seeAll={
                                      ctx.data?.see_all
                                        ? ctx.data?.see_all[ctx.language]
                                        : 'See All'
                                    }
                                    video={
                                      ctx.data?.video
                                        ? ctx.data?.video[ctx.language]
                                        : 'Video'
                                    }
                                  />
                            </div>
                          );
                        })
                      ) : (
                        <NoData
                          title={ctx.data?.no_playlists_added_yet[ctx.language]}
                        />
                      )}
                      </div>
                    </InfiniteScroll>
                  ) : (
                    <SignInErrorModal
                    country={localStorage.getItem('country')}
                    setShowErrorModal={setShowErrorModal}
                      showErrorModal={showErrorModal}
                      onHide={() => {
                        setShowErrorModal(false);
                        navigate('/');
                      }}
                    />
                  )}
                </>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PlayList;
