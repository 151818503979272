import style from './SignIn.module.css';
import Loader from '../../../spinner/Loader';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../../error/ErrorMessage';
import Button from '../../../buttons/authButton/Button';
import Buttons from '../../../buttons/authButton/Buttons';
import { LanguageContext } from '../../../../context/LanguageContext';
import { authValidation } from '../../../../utils/helpers/authValidation';
import { operationsWithPhoneNumber } from '../../../../utils/helpers/operationsWithPhoneNumber/operationsWithPhoneNumber';

const SignIn = (props) => {
  const {
    setShow,
    phoneNumber,
    validPhoneNumber,
    showAuth,
    setPhoneNumber,
    isLoading,
    requestOTP,
    onPhoneChange,
    recaptchaId,
    disabled,
    onPress,
    error,
    setError,
    setDisabled,
    letsStart,
    country,
  } = props;

  const location = useLocation();
  const [checkedPhoneNumber, setCheckedPhoneNumber] = useState(false);
  const checkCountry = document.getElementsByClassName('selected-flag');
  const stateStatus =
    window.sessionStorage.getItem('stateStatus') === 'true' && phoneNumber
      ? true
      : false;
  const [isValid, setIsValid] = useState(stateStatus ?? false);

  useEffect(() => {
    if (checkCountry && checkCountry[0]) {
      checkCountry[0].style.borderRight = '1px solid #212529';
    }
  }, [checkCountry, showAuth, location]);

  useEffect(() => {
    if (!isValid && !showAuth && letsStart) {
      setPhoneNumber('');
    }
  }, [props, isValid, setPhoneNumber, showAuth, validPhoneNumber, letsStart]);

  const signInHandler = (e) => {
    if (disabled.includes('signIn') && (!phoneNumber || !isValid)) {
      setCheckedPhoneNumber(true);
    } else {
      setCheckedPhoneNumber(false);
      requestOTP(e);
      setDisabled(['provider', 'codeVerification']);
      onPress();
    }
  };

  useEffect(() => {
    if (isValid) {
      setDisabled(['codeVerification']);
    } else {
      setDisabled(prev => [...prev, 'signIn']);
    }
  }, [setDisabled, isValid]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${style.modal_phone_number_container} d-flex flex-wrap`}
          >
            <div
              className={`${style.auth_container} d-flex flex-wrap w-100 justify-content-center`}
            >
              {letsStart && (
                <div
                  className={`d-flex justify-content-center align-items-center w-100`}
                >
                  <span
                    className={`${style.lets_start} align-items-center text-center`}
                  >
                    {ctx.data?.lets_start[ctx.language]}
                  </span>
                </div>
              )}
              <form
                className={`${style.auth_page} d-flex justify-content-center align-items-center w-100 flex-wrap`}
                onSubmit={signInHandler}
              >
                <div className='codeVerification'>
                  <PhoneInput
                    dropdownClass={`${style.drop_down} w-100 position-relative`}
                    className={`${style.phone_input} d-flex`}
                    buttonClass={`${style.height_45}`}
                    required
                    country={`${country}`}
                    value={phoneNumber}
                    onEnterKeyPress={signInHandler}
                    onChange={(phone, country) =>
                      operationsWithPhoneNumber.changePhoneNumber(
                        phone,
                        country,
                        setCheckedPhoneNumber,
                        onPhoneChange,
                        authValidation,
                        phoneNumber,
                        setIsValid,
                        style,
                        setDisabled
                      )
                    }
                    type='tel'
                    aria-describedby='emailHelp'
                    isValid={(inputNumber, country) =>
                      authValidation.isValidCountry(inputNumber, country)
                    }
                  />
                  {checkedPhoneNumber && (
                    <ErrorMessage
                      text={ctx.data.error_meesage[ctx.language]}
                      className={style.font_size_10}
                    />
                  )}
                </div>
                <div id={recaptchaId && recaptchaId} className={'d-none'} />
                {!isLoading.includes('loginByPhoneNumber') ? (
                  <Button
                    divStyle={`${style.max_width_350} w-100 justify-content-center d-flex`}
                    buttonStyle={`${style.next_button} w-100 border-0 justify-content-center`}
                    id='enter'
                    disabled={
                      disabled.includes('signIn') ||
                      disabled.includes('provider')
                    }
                    text={ctx.data?.next[ctx.language]}
                    type='submit'
                    setShow={setShow}
                  />
                ) : (
                  isLoading.includes('loginByPhoneNumber') && (
                    <Loader auth={true} />
                  )
                )}
              </form>
            </div>
            <Buttons
              disabled={disabled.includes('provider')}
              setDisabled={setDisabled}
              setShow={setShow}
              error={error}
              setError={setError}
              isValid={isValid}
            />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default SignIn;
