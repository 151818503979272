import classes from './Authors.module.css';
import NoData from '../../components/noData/NoData';
import React, { useEffect, useState } from 'react';
import Loader from '../../components/spinner/Loader';
import { useDispatch, useSelector } from 'react-redux';
import allAuthors from '../../store/authors/allAuthors';
import PageTitle from '../../components/pageTitle/PageTitle';
import { LanguageContext } from '../../context/LanguageContext';
import PageinationData from '../../components/pageination/Pageination';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';
import AuthorBooksCard from '../../components/card/authors/AuthorBooksCard';

const Authors = () => {
  const dispatch = useDispatch();
  const page = sessionStorage.getItem('authorsPagePageination');
  const authors = useSelector((state) => state.allAuthors.allAuthors);
  const [activeButton, setActiveButton] = useState(+page ? +page : 1);

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Authors_page')
    dispatch(allAuthors(process.env.REACT_APP_AUTHORS_LIMIT, activeButton));
  }, [dispatch, activeButton]);
  
  const activePageData = (activePage) => {
    setActiveButton(activePage);
  };

  if (Object.keys(authors?.data)?.length === 0) {
    return <Loader />;
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`d-flex w-100 flex-wrap ${classes.gap_50}`}
          >
            <PageTitle title={ctx.data?.authors[ctx.language].toUpperCase()} />
            <div
              className={`d-flex w-100 justify-content-center ${classes.padding_0_20}`}
            >
              {authors.data.newAuthorsList.length > 0 ? (
                <div
                  className={`${classes.card_item_container} ${classes.gap_20} d-flex w-100 flex-wrap col-8`}
                >
                  {authors.data.newAuthorsList.map((author) => {
                    return (
                      <AuthorBooksCard
                        id={author._id}
                        key={author._id}
                        img={author.image}
                        data={author}
                        authorName={author.name[ctx.language]}
                      />
                    );
                  })}
                </div>
              ) : (
                <NoData title={ctx.data?.no_data[ctx.language]} />
              )}
            </div>
            {authors.data.pageCount > 1 && (
              <PageinationData
                pageName='authorsPagePageination'
                activePage={activeButton}
                setActivePage={activePageData}
                pagesCount={authors.data.pageCount}
              />
            )}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Authors;
