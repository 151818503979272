import React from 'react';
import classes from './FontSizeConfiguration.module.css';

const FontSizeConfiguration = (props) => {
  const { decreaseFontSize, increaseFontSize } = props;
  
  return (
    <div
      className={`${classes.text_settings_container} d-flex w-100 justify-content-between align-items-center`}
    >
      <div
        className={`${classes.text_setting} w-100 d-flex justify-content-center`}
        onClick={() => {
          decreaseFontSize();
        }}
      >
        <button className={`${classes.smaller_text} bg-transparent border-0`}>
          A
        </button>
      </div>
      <div className={`${classes.border_right} ${classes.height_40}`} />
      <div
        className={`${classes.text_setting} w-100 d-flex justify-content-center`}
        onClick={increaseFontSize}
      >
        <button className={`${classes.enlarge_text} bg-transparent border-0`}>
          A
        </button>
      </div>
    </div>
  );
};
export default FontSizeConfiguration;
