import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classes from './SingleAuthor.module.css';
import AuthorData from '../authorData/AuthorData';
import Loader from '../../../components/spinner/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import authorById from '../../../store/authors/authorById/AuthorById';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import CardBestsellers from '../../../components/card/cardBestsellers/CardBestsellers';

const SingleAuthor = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [author, setAuthor] = useState({});
  const [showMore, setShowMore] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  window.scroll(0, 0);

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Author_page');
    dispatch(authorById(params.id));
  }, [dispatch, params]);

  const fetchData = useCallback(async () => {
    let response = await dispatch(authorById(params.id));
    if (response.status === 200) {
      setAuthor(response.data);
    }
    setShowLoader(false);
  }, [dispatch, params]);

  useEffect(() => {
    setShowLoader(true);
    fetchData();
  }, [dispatch, fetchData]);

  const changeImagePosition = useCallback(() => {
    setShowMore(true);
    let bio = document.getElementById('bio');
    let authorImage = document.getElementById('authorImage');

    if (bio) {
      bio.style.overflow = 'hidden';
      bio.scrollTop = 0;
    }

    if (window.innerWidth > 820 && authorImage) {
      authorImage.style.top = 'unset';
    } else if (authorImage) {
      authorImage.style.top = `-${authorImage.offsetHeight / 2 + 15}px`;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', changeImagePosition);
    return () => window.removeEventListener('resize', changeImagePosition);
  }, [changeImagePosition]);

  const showOrHide = () => {
    setShowMore(!showMore);
    let bio = document.getElementById('bio');
    if (showMore && bio) {
      bio.style.overflow = 'auto';
    } else if (bio) {
      bio.scrollTop = 0;
      bio.style.overflow = 'hidden';
    }
  };

  if (!author?.bio || Object.keys(author?.bio).length === 0) return;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            {showLoader ? (
              <Loader />
            ) : (
              <div className={`d-flex flex-column ${classes.gap_50} mt-5`}>
                  <AuthorData
                    author={author}
                    showMore={showMore}
                    showOrHide={showOrHide}
                  />
                <div>
                  <div
                    className={`d-flex ${classes.books_contnt} mx-auto w-auto d-flex flex-wrap m-auto`}
                  >
                    {author?.books &&
                      author.books.map((item, index) => {
                        return (
                          <CardBestsellers
                            titles={['name']}
                            dataContainer={classes.min_height_420}
                            bestsellers={true}
                            data={item}
                            img={item.image.imageURL}
                            key={`Books_${index}`}
                            mx_auto={`mx-auto ${classes.image_container}`}
                            categoriesData={item.categories}
                            className={classes.main_width}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default SingleAuthor;
