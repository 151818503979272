import Cookies from 'js-cookie';
import Listen from '../listen/Listen';
import classes from './BooksData.module.css';
import BookDetail from './bookDetail/BookDetail';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/spinner/Loader';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/user/UserContext';
import BooksInformation from './booksInformation/BooksInformation';
import { LanguageContext } from '../../../context/LanguageContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import fetchDownLoad from '../../../store/purchases/downLoad/FetchDownLoad';
import readedBooks from '../../../store/readBooks/readedBooks/ReadedBooks';
import deleteReadBook from '../../../store/readBooks/deleteReadBook/DeleteReadBook';
import addToReadBooks from '../../../store/readBooks/addToReadBooks/AddToReadBooks';
import { ReadBooksContext } from '../../../context/readBooksContext/ReadBooksContext';
import fetchNewPurchases from '../../../store/purchases/newPurchases/FetchNewPurchases';
import ConfirmationModal from '../../../components/modal/confirmationModal/ConfirmationModal';
import SignInErrorModal from '../../../components/modal/error/signInErrorModal/SignInErrorModal';

const BooksData = (props) => {
  const productId = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [listen, setListen] = useState();
  const token = Cookies.get('auth_token');
  const navigateToReadBook = useNavigate();
  const [isShow, setIsSHow] = useState(true);
  const { data, onClick, addToFavorite, id } = props;
  const { user, setUserData } = useContext(UserContext);
  const [isTokenExists, setIsTokenExists] = useState(false);
  const readedBooksData = useSelector((state) => state.readedBooks.data.data);
  const [alreadyReaded, setAlreadyReaded] = useState([]);
  const purchasesData = user.purchases
    ?.filter((item) => item.inUserHistory === true)
    ?.map((item) => item.product);
  const filteredData = alreadyReaded?.find((item) =>
    typeof item.product === 'object'
      ? item.product._id === productId.id
      : item.product === productId.id
  );
  const nowReadingBook = JSON.parse(
    localStorage.getItem('readingPageLocation')
  );
  const [markAsRead, setMarkAsRead] = useState(false);

  const read = nowReadingBook?.find(
    (item) => item.productId === productId?.id
  );
  const navigate = useNavigate()
  const { readBooks, setReadBooks} = useContext(ReadBooksContext);

  useEffect(() => {
    if (token) {
      dispatch(readedBooks());
    }
  }, [dispatch, token, location]);

  useEffect(() => {
    if (location) {
      setAlreadyReaded(readedBooksData);
    }
  }, [location, readedBooksData]);

  const productsHandler = async (type) => {
    if (token) {
      if (type === 'read' || type === 'listen') {
        window.scroll(0, 0);
        setIsSHow(false);
        const response = await dispatch(
          fetchDownLoad(
            productId.id,
            type === 'read' ? 'book' : 'audiobook',
            type !== 'read' && data.audioBook && data.audioBook[0].episode
          )
        );
        response.payload.file.status === 200 && type === 'read'
          ? navigateToReadBook(`/products/read-book/${id}`)
          : setListen(true);
        setIsSHow(true);
      } else if (type === 'download') {
        const mutableData = { ...user };
        const response = await dispatch(fetchNewPurchases(id));
        if (response?.payload?.newPurchases.status === 200) {
          mutableData.purchases = response.payload.newPurchases.data;
          setUserData(mutableData);
        }
      }
    } else {
      setIsTokenExists(true);
    }
  };

  const alreadyReadedBooksHandler = async () => {
    let mutableData = {...readBooks}
    if (token) {
      let readBooks = [];
      if (!filteredData) {
        const response = await dispatch(addToReadBooks(productId.id));
        if (response.payload.status === 200) {
          setMarkAsRead(false);
          for (let item of response.payload.data) {
            readBooks.push(item.product);
          }
          if (
            nowReadingBook &&
            productId?.id ===
              nowReadingBook[nowReadingBook?.length - 1]?.productId
          ) {
            const filter = nowReadingBook.filter(
              (item) => item.productId !== productId.id
            );
            mutableData = filter
            setReadBooks(mutableData)
            filter.length >= 1 && navigate(`/products/${filter[filter.length-1].productId}`)
            localStorage.setItem('readingPageLocation', JSON.stringify(filter));

          }
          setAlreadyReaded(response.payload.data);
        }
      } else {
        const response = await dispatch(deleteReadBook(productId.id));
        if (response.payload.status === 200) {
          setMarkAsRead(false);
          for (let item of response.payload.data) {
            readBooks.push(item.product);
          }
          setAlreadyReaded(response.payload.data);
        }
      }
      window.localStorage.setItem('readBooks', JSON.stringify(readBooks));
    } else {
      setIsTokenExists(true);
    }
  };

  const hideListenModal = () => {
    setListen(false);
    window.scroll(0, 0);
  };

  useEffect(() => {
    let readBooks = [];
    if (readedBooksData) {
      for (let item of readedBooksData) {
        readBooks.push(item.product._id);
      }
      localStorage.setItem('readBooks', JSON.stringify(readBooks));
    }
  }, [readedBooksData, location]);

  if (!isShow) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div
              className={`${classes.single_product_page_container} ${classes.margin_top} d-flex justify-content-center w-100 align-items-start text-decoration-none`}
            >
              <div
                className={`${classes.single_product_page_container} ${classes.selected_book_data_container} d-flex justify-content-center w-100 align-items-start text-decoration-none`}
              >
                <div
                  className={`${classes.single_book_data_container} d-flex align-items-center`}
                >
                  <div className={classes.img_container}>
                    <img
                      className='w-100'
                      src={`${process.env.REACT_APP_DEV_URI}${data.image?.imageURL}`}
                      alt=''
                    />
                  </div>
                  <div
                    className={`${classes.single_product_page_container} ${classes.selected_book_data} ${classes.children_container} d-flex justify-content-center w-100 align-items-start text-decoration-none`}
                  >
                    <BooksInformation
                      data={data}
                      styles={classes.first_child}
                    />
                    <div
                      className={`${classes.single_product_page_container} ${classes.books_data_container} ${classes.display_contents} flex-wrap d-flex justify-content-center w-100 align-items-start text-decoration-none h-100`}
                    >
                      <div
                        className={`${classes.single_product_page_container} ${classes.padding} ${classes.second_child} flex-wrap d-flex justify-content-center w-100 align-items-start text-decoration-none`}
                      >
                        <div
                          className={`${classes.single_product_page_container} d-flex justify-content-center w-100 align-items-start text-decoration-none`}
                        >
                          <span className={`${classes.name_title} w-100`}>
                            {data.name[ctx.language]}
                          </span>
                          <div
                            onClick={onClick}
                            className={classes.want_read_container}
                          >
                            {!addToFavorite || !token ? (
                              <img
                                className='w-100'
                                src='../assets/products/save/pasiveSave.svg'
                                alt=''
                              />
                            ) : (
                              <img
                                className='w-100'
                                src='../assets/products/save/activeSave.svg'
                                alt=''
                              />
                            )}
                          </div>
                        </div>
                        <span className={`${classes.authorName} w-100`}>
                          {data?.author?.name[ctx.language]}
                        </span>
                        {token && (
                          <button
                            className={`${classes.mark_as_read} border-0 bg-transparent d-flex w-100 p-0`}
                            onClick={() => setMarkAsRead(true)}
                          >
                            {!filteredData
                              ? ctx.data?.mark_as_read[ctx.language]
                              : ctx.data?.It_was_read[ctx.language]}
                          </button>
                        )}
                      </div>
                      <BookDetail
                        read={read}
                        data={data}
                        id={id}
                        purchasesData={purchasesData}
                        productsHandler={productsHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isShow ? (
              <Listen data={data} listen={listen} onHide={hideListenModal} />
            ) : (
              <Loader audio={true} />
            )}
            <SignInErrorModal
            country={localStorage.getItem('country')}
            setShowErrorModal={setIsTokenExists}
              showErrorModal={isTokenExists}
              onHide={() => {
                setIsTokenExists(false);
              }}
            />
            <ConfirmationModal
              show={markAsRead}
              onHide={() => setMarkAsRead(false)}
              logOutHandler={alreadyReadedBooksHandler}
              titles={{
                max_title: !filteredData
                  ? ctx?.data?.mark_as_read_message[ctx.language]
                  : ctx?.data?.delete_already_read[ctx.language],
              }}
            />
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default BooksData;
