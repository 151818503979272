import { useEffect } from 'react';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TextSettings from './textSettings/TextSettings';
import classes from './ReadingPageSettings.module.css';

const ReadingPageSettings = (props) => {
  const productId = useParams();
  const [fontFamily, setFontFamily] = useState();

  const {
    defaultChecked,
    isSwitch,
    reduceSpacing,
    letterSpacing,
    reduceLetterSpacing,
    brightnessModeHandler,
    scrollHandler,
    decreaseFontSize,
    increaseFontSize,
    fontFamilyHandler,
    night,
    textSpacing,
    setTextSpacing,
    addSpacing,
    changeFontSize
  } = props;

  const [textSettings, setTextSettings] = useState(false);

  const fontType = (fontType) => {
    setFontFamily(fontType);
  };
  useEffect(() => {
    fontFamilyHandler(fontFamily);
  }, [props, fontFamily, fontFamilyHandler]);

  const navigatePrev = () => {
    document.body.style.background = 'white';
  };

        return (
          <>
            <div className={`${classes.read_books_setings_container} d-flex w-100 justify-content-between align-items-center`}>
              <Link
                to={`/products/${productId.id}`}
                className={`${classes.prev_button_container} d-flex user-select-none`}
                onClick={navigatePrev}
              >
                <img
                  src={
                    night !== 'darck'
                      ? '../../assets/readBook/prev.svg'
                      : '../../assets/readBook/prevWhite.svg'
                  }
                  alt=''
                />
              </Link>
              <div className='d-flex flex-wrap'>
                <button
                  className={`${classes.text_settings_img_container} bg-transparent border-0 user-select-none`}
                  onClick={() => setTextSettings(!textSettings)}
                >
                  <img
                  className='w-100'
                    src={
                      night !== 'darck'
                        ? '../../assets/readBookSettings/text_settings.svg'
                        : '../../assets/readBookSettings/text_settingsWhite.svg'
                    }
                    alt=''
                  />
                </button>
                {textSettings && (
                  <div className={`${classes.read_book_settings} d-flex w-100 position-absolute flex-wrap user-select-none`}>
                    <TextSettings
                    changeFontSize={changeFontSize}
                      decreaseFontSize={decreaseFontSize}
                      increaseFontSize={increaseFontSize}
                      letterSpacing={letterSpacing}
                      reduceLetterSpacing={reduceLetterSpacing}
                      reduceSpacing={reduceSpacing}
                      fontType={fontType}
                      scrollHandler={scrollHandler}
                      defaultChecked={defaultChecked}
                      isSwitch={isSwitch}
                      brightnessModeHandler={brightnessModeHandler}
                      textSpacing={textSpacing}
                      setTextSpacing={setTextSpacing}
                      addSpacing={addSpacing}
                    />
                  </div>
                )}
              </div>
            </div>
            {textSettings ? (
                  <div
                    className={`${classes.transparent_container} w-100 bg-transparent position-absolute`}
                    onClick={() => setTextSettings(false)}
                  />
                ) : null} </>
  );
};

export default ReadingPageSettings;
