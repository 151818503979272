import CartoonSearch from '../cartoon/filter/search/CartoonSearch';
import classes from './Products.module.css';
import { useLocation } from 'react-router-dom';
import FilterData from './filterData/FilterData';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Titles from '../../components/title/Titles';
import Loader from '../../components/spinner/Loader';
import { useDispatch, useSelector } from 'react-redux';
import BackDrop from '../../components/backDrop/BackDrop';
import NoData from '../../components/noData/NoData';
import fetchPopular from '../../store/filter/FetchPopular';
import fetchProducts from '../../store/products/FetchProducts';
import { LanguageContext } from '../../context/LanguageContext';
import PageTitle from '../../components/pageTitle/PageTitle';
import FilterComponents from './filterComponents/FilterComponents';
import PageinationData from '../../components/pageination/Pageination';
import Button from '../../components/buttons/authButton/Button';
import ProductsSlider from '../../components/slider/productsSlider/ProductsSlider';
import productCategories from '../../store/category/ProductCategories';
import { filterProducts } from '../../utils/helpers/filterProducts';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';

const Products = () => {
  const limit = process.env.REACT_APP_MY_PRODUCTS_LIMIT;
  const ref = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [page, setPage] = useState();
  const pageData = sessionStorage.getItem('activeButton');
  const [filtered, setFiltered] = useState(false);
  const popular = useSelector((state) => state.popular.popular);
  const product = useSelector((state) => state.products.products.products);
  const categories = useSelector((state) => state.categories.categories);
  const checkedData = JSON.parse(sessionStorage.getItem('checkedData'))
  const [selectedFilters, setSelectedFilters] = useState(location.state?.checkedData ? JSON.parse(location.state.checkedData) : checkedData ? checkedData : {});
  const [activeButton, setActiveButton] = useState(+pageData ? +pageData : 1);
  const [searchText, setSearchText] = useState('');
  const [showSearchResult, setShowSearchResult] = useState(false);
  const filteredData = location.state?.checkedData ? JSON.parse(location.state.checkedData) : checkedData ? checkedData : {}
  const [data, setData] = useState({
    categories: filteredData?.categories ?? [],
    authorsId: filteredData?.authors ?? [],
    ageGroup: filteredData?.ageGroup ?? [],
    type: filteredData?.type ?? [],
    rating: filteredData?.rating ?? [],
  });
  const [showLoader, setShowLoader] = useState(true);
  const authors = useSelector((state) => state.allAuthors.allAuthors.data.newAuthorsList);
  const [sort, setSort] = useState('')
  const [lang, setLang] = useState('en')
  const [filterShow, setFilterShow] = useState(['show']);
  
  const fetchData = useCallback(async (filter, page, text, limit, sort, lang) => {
    await dispatch(fetchProducts(filter, page, text, limit, sort, lang));
  },
    [dispatch]
  );

  useEffect(() => {
    setShowLoader(true)
    let filterObj = {};
    for (let key in selectedFilters) {
      if (selectedFilters[key].length > 0) filterObj[key] = selectedFilters[key];
    }
    fetchData(filterObj, activeButton, searchText, limit, sort, lang);
  }, [selectedFilters, activeButton, searchText, fetchData, limit, sort, lang, location]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  useEffect(() => {
    let filters = {};
    for (let key in data) {
      if (data[key]?.length > 0) {
        key !== 'type'
          ? (filters[key] = data[key])
          : (filters[key] = data[key].length > 1 ? 'both' : data[key][0]);
      }
    }
    setSelectedFilters(filters);
    Object.keys(filters).length > 0 && !filtered ? setPage(1) : setPage(null);
  }, [activeButton, filtered, data]);

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Books_page')
    dispatch(productCategories());
    dispatch(fetchPopular());
  }, [dispatch]);

  useEffect(() => {
    setShowLoader(false);
  }, [product]);

  useEffect(() => {
    if((activeButton !== 1 && product?.products?.length === 0)) {
      setActiveButton(1)
    }
  }, [activeButton, product,selectedFilters])

  const filteredProducts = (type) => e => {
    const value = type === 'rating' ? +e.target.value : e.target.value;
    let updateList = { ...data };
    if (e.target.checked) {
      updateList[type]?.push(value);
      window.sessionStorage.setItem('checkedData', JSON.stringify(updateList));
    } else {
      updateList[type] = updateList[type]?.filter((item) => item !== value);
      window.sessionStorage.setItem('checkedData', JSON.stringify(updateList));
    }
    setData(updateList);
  };

  useEffect(() => {
    if (page && activeButton !== 1 && !filtered) {
      setFiltered(true);
    }
  }, [page, activeButton, filtered]);

  const clearFilters = () => {
    window.sessionStorage.removeItem('checkedData');
    setData({ categories: [], authorsId: [], ageGroup: [], type: [], rating: [] });
  };

  const searchCartoons = (e, text) => {
    if (e && (!e.target.value.length || e.target.value.length > 0)) {
      setSearchText(e.target.value);
    } else {
      setSearchText(text);
    }
    setActiveButton(1);
  };

  const filterHandler = () => {
    setFilterShow(!filterShow.includes('filter') ? ['filter'] : []);
    window.scroll(0, 0);
  };

if(!product) return <Loader/>

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const ratings = filterProducts.productsrating();
        const booksType = filterProducts.productsTypes(ctx.data, ctx.language);
        return (
          <>
            <div
              className={`${classes.product_container} ${classes.products_container} flex-wrap d-flex justify-content-center`}
              onClick={() => setShowSearchResult(false)}
             >
              <PageTitle
                title={ctx.data?.online_library[ctx.language].toUpperCase()}
              />
              <div
                className={`${classes.products_container} ${classes.filter_products} ${classes.data_container} d-flex position-relative w-100 justify-content-center`}
               >
                <FilterComponents
                  defaultCategoryToggled={
                    filteredData &&
                    filteredData?.categories?.length > 0 ? ctx.data?.categories[ctx.language].toLowerCase() : ''
                  }
                  categories={categories}
                  products={product}
                  activePage={page}
                  clearFilter={clearFilters}
                  filterProductsChange={filteredProducts}
                  checkedData={data}
                  authors={authors}
                  ratings={ratings}
                  booksType={booksType}
                  filterShow={filterShow}
                  setFilterShow={setFilterShow}
                />
                <div
                  className={`${classes.filter_data_container} d-flex flex-wrap w-100 flex-column align-items-center`}
                >
                  <CartoonSearch
                    searchCartoons={searchCartoons}
                    inputRef={ref}
                    showSearchResult={showSearchResult}
                    setShowSearchResult={setShowSearchResult}
                    searchFrom='books'
                    languageKey={ctx.language}
                  />
                  <div
                    className={`${classes.filter_data_container} d-flex flex-wrap w-100 flex-column align-items-center`}
                  >
                    {!filterShow.includes('show') && (
                      <Button
                        divStyle='w-100 position-relative'
                        buttonStyle={`${classes.side_bar_icon} d-flex border-0 bg-transparent p-0`}
                        text={
                          ctx.data?.filter[ctx.language]
                            .charAt(0)
                            .toUpperCase() +
                          ctx.data?.filter[ctx.language].slice(1)
                        }
                        onClick={filterHandler}
                      />
                    )}

                    {(product && product.products?.length === 0) ? (
                      <NoData title={ctx.data?.no_data[ctx.language]} />
                    ) : (
                      <>
                        <FilterData
                          products={product.products}
                          selectedFilters={selectedFilters}
                          categories={categories}
                          activePage={activeButton}
                          pageCount={product.pageCount}
                          setLang={setLang}
                          setSort={setSort}
                        />
                        {product.pageCount > 1 && (
                          <PageinationData
                            pageName='activeButton'
                            activePage={activeButton}
                            setActivePage={setActiveButton}
                            pagesCount={product.pageCount}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${classes.products_container} flex-wrap d-flex position-relative w-100 justify-content-center`}
              >
                <Titles
                  title={{
                    subtitle: ctx.data?.may_like[ctx.language],
                    title: ctx.data?.readers_picked[ctx.language],
                  }}
                />
                <div
                  className={
                    showLoader
                      ? `${classes.abs} position-fixed top-0 start-0 w-100 d-flex h-100`
                      : `${classes.abs} ${classes.hide} position-fixed top-0 start-0 w-100 d-none`
                  }
                >
                  <Loader />
                </div>
                <ProductsSlider popular={popular} />
              </div>
            </div>
            {filterShow.includes('filter') && (
              <BackDrop onClick={() => setFilterShow(['bacDrop'])} className={classes.backdrop_container}/>
            )}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Products;
