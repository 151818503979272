import classes from './CartoonSearch.module.css';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import suggestedCartoons from '../../store/cartoons/suggestedCartoons/SuggestedCartoons';

const CartoonSearch = (props) => {
  const { showSaerchResult, setShowSearchResult, languageKey, search, setInputIsFocused} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const [showClearImg, setShowClearImg] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const suggestedCartoonsData = useSelector((state) => state.suggestedCartoons.suggestedCartoons);

  useEffect(() => {
    setSelectedIndex(-1);
    let searchText = searchParams.get('search');
    let searchInput = document.getElementById('search_input');
    if (searchText && searchInput) {
      searchInput.value = searchText;
      setSearchValue(searchText);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!showSaerchResult) setSearchResult([]);
  }, [showSaerchResult]);

  useEffect(() => {
    if (suggestedCartoonsData?.length > 0) {
      setSearchResult(suggestedCartoonsData);
    } else {
      setSearchResult([]);
    }
  }, [suggestedCartoonsData]);



  useEffect(() => {
    let searchModal = document.getElementById('search_modal');
    let searchInput = document.getElementById('search_input');
    if (searchModal && searchInput) {
      searchModal.style.width = `${searchInput.offsetWidth}px`;
    }
  }, [showSaerchResult]);

  const searchCartoon = useCallback(
    (value) => {
      let text = value && typeof value == 'string' ? value : searchValue;
      setSelectedIndex(-1);
      setShowSearchResult(false);
      setShowClearImg(false);
      if (searchValue !== '') {
        navigate({
          pathname: '/cartoon',
          search: `?search=${text}`,
        });
      }
    },
    [searchValue, navigate, setShowSearchResult]
  );

  const cancel = () => {
    setShowClearImg(false);
    setShowSearchResult(false);
    setSearchValue('');
    document.getElementById('search_input').value = '';
  };

  const onKeyDown = (event) => {
    let searchInput = document.getElementById('search_input');
    let index = 0;
    switch (event.keyCode) {
      case 13:
        searchCartoon();
        break;
      case 38:
        selectedIndex > 0
          ? (index = selectedIndex - 1)
          : (index = searchResult.length - 1);
        break;
      case 40:
        if (
          selectedIndex >= 0 &&
          selectedIndex < searchResult.length - 1 &&
          searchInput
        ) {
          index = selectedIndex + 1;
        }
        break;
      default:
        return;
    }
    if (event.keyCode !== 13) {
      setSelectedIndex(index);
      searchInput.value = searchResult[index]?.title[languageKey];
      setSearchValue(searchResult[index]?.title[languageKey]);
    }
  };

  const suggestionClick = (title) => {
    document.getElementById('search_input').value = title;
    setSearchValue(title);
    setShowSearchResult(title !== '');
    setShowClearImg(title !== 0);
    searchCartoon(title);
  };

  const getSuggestedCartoons = (type) => e =>  {
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value.replace(/ +(?= )/g, '');
    value !== '' && dispatch(suggestedCartoons(e.target.value));
    setShowSearchResult(value !== '');
    setShowClearImg(value !== '');

    if (type === 'click' && setInputIsFocused) {
      setInputIsFocused(true);
    } else if (type === 'change') {
      setSelectedIndex(-1);
      setSearchValue(value);
    }
  };

  return (
    <div className={`${classes.margin_bottom_15} ${classes.search_content}`}>
      <div
        className={`${classes.search_container} d-flex justify-content-center`}
      >
        <div className={classes.width_40_percent}>
          <div className={classes.position_relative}>
            <input
              type='text'
              id='search_input'
              className={showClearImg  ? `${classes.padding_right_25} ${classes.form_control} ${classes.position_relative} ${classes.search_input_container} d-block w-100 font-weight-normal` : `${classes.form_control} ${classes.position_relative} ${classes.search_input_container} d-block w-100 font-weight-normal`}
              aria-describedby='emailHelp'
              placeholder={search}
              onClick={getSuggestedCartoons('click')}
              onChange={getSuggestedCartoons('change')}
              onKeyDown={onKeyDown}
              autoComplete='off'
            />
            <img
              src='../../assets/video/greySearch.svg'
              alt='search'
              className={classes.search_img}
            />
            {showClearImg ? (
              <img
                src='../../assets/video/blackClose.png'
                alt='clear'
                className={classes.clear_img}
                onClick={cancel}
              />
            ) : null}
            {showSaerchResult ? (
              <div
                className={
                  searchResult?.length > 0
                    ? `${classes.padding_10_0} ${classes.search_modal} d-flex flex-column`
                    : `${classes.search_modal} d-flex flex-column`
                }
                id='search_modal'
              >
                {searchResult?.map((item, index) => {
                  return (
                    <div id='search_modal_content' key={`suggestion_${index}`} className={classes.search_modal_content}>
                      <h6
                        className={index === selectedIndex ? `${classes.selected_search_modal}`  : '' }
                        onClick={() => suggestionClick(item.title[languageKey])}
                        onMouseEnter={() => setSelectedIndex(index)}
                        onMouseLeave={() => setSelectedIndex(-1)}
                      >
                        {item.title[languageKey]}
                      </h6>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className={`${classes.search} d-flex align-items-center`}>
          <img src='../../assets/video/whiteSearch.svg' alt='search' />
          <button
            className={`${classes.search_button} border-0`}
            id='searchButton'
            onClick={searchCartoon}
          >
            {search}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartoonSearch;
