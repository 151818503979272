import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/user/UserContext';
import { useDispatch, useSelector } from 'react-redux';
import CartoonCard from '../cartoon/cartoonCard/CartoonCard';
import { LanguageContext } from '../../context/LanguageContext';
import uploadedCartoons from '../../store/cartoons/uploadedCartoons/UploadedCartoons';
import fetchCartoonCategories from '../../store/cartoons/categories/FetchCartoonCategories';
import NoData from '../../components/noData/NoData';
import classes from './UserCartoons.module.css';
import PageTitle from '../../components/pageTitle/PageTitle';
import PageinationData from '../../components/pageination/Pageination';
import MyBooksTab from '../myLibrary/myBooks/tabs/MyBooksTab';
import watchLater from '../../store/cartoons/watchLater/watchLater/WatchLater';
import Loader from '../../components/spinner/Loader';
import deleteCartoon from '../../store/cartoons/deleteCartoon/DeleteCartoon';
import deleteFromWatchLater from '../../store/cartoons/watchLater/deleteWatchLater/DeleteFromWatchLater';
import Cookies from 'js-cookie';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';
import { useLocation } from 'react-router-dom';
import { constantData } from '../../utils/helpers/constantData';

const UserCartoons = () => {
  const limit = process.env.REACT_APP_USER_CARTOONS_LIMIT;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { user } = useContext(UserContext);
  const token = Cookies.get('auth_token');
  const cartoonCategories = useSelector(
    (state) => state.cartoonCategories.cartoonCategories
  );
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem('cartoonsActiveTab') ?? 0
  );
  const [activeButton, setActiveButton] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [id, setId] = useState();
  const uploadedCartoon = useSelector((state) => state.uploadedCartoons.data);
  const location = useLocation();

  localStorage.setItem('firstLoadDone', 1);
  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_User_cartoons');
    dispatch(fetchCartoonCategories());
  }, [dispatch]);



  useEffect(() => {
    if (token) {
      dispatch(uploadedCartoons());
    }
  }, [dispatch, token]);

  const tabsHandler = useCallback(
    async (id) => {
      setId(id);
      window.sessionStorage.setItem('cartoonsActiveTab', id);
      if (id >= 0 && id < 2 && user._id) {
        setShowLoader(true);
        const requestTypes = [uploadedCartoons, watchLater];
        const response = await dispatch(
          +id === 0
            ? requestTypes[+id](limit, activeButton)
            : requestTypes[+id](limit, activeButton)
        );
        if (response.payload.data.status === 200) {
          setData(
            +id === 1
              ? response.payload.data.data.watchLaterList
              : response.payload.data.data.cartoons
          );
          setPageCount(response.payload.data.data.pageCount);
          setShowLoader(false);
        }
      }
    },
    [dispatch, activeButton, user, limit]
  );

  useEffect(() => {
    if (
      location.state?.path !== 'profile' ||
      location.state?.path === 'undefined'
    ) {
      tabsHandler(0);
      setActiveTab(0);
    }
  }, [location, tabsHandler]);

  useEffect(() => {
    tabsHandler(activeTab);
  }, [activeTab, tabsHandler]);

  useEffect(() => {
    activeTab >= 0 && sessionStorage.setItem('cartoonsActiveTab', activeTab);
    setActiveButton(1);
  }, [activeTab]);

  const deleteCartoonHandler = async (cartoonId, id) => {
    const requests = [deleteCartoon, deleteFromWatchLater];
    const response = await dispatch(requests[id](cartoonId));
    if (response.payload.data.status === 200) {
      const filteredData = data.filter((item) => item._id !== cartoonId);
      setData(filteredData);
    }
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div id='my-cartoons'>
            <div
              className={`${classes.gap_50} justify-content-center d-flex w-100 flex-wrap`}
            >
              <PageTitle
                title={ctx.data?.my_cartoons[ctx.language].toUpperCase()}
              />
              <div
                className={`${classes.user_cartoons_container} ${classes.gap_20} justify-content-center d-flex w-100 flex-wrap`}
              >
                <MyBooksTab
                  keys={constantData.userCartoons}
                  activeTab={activeTab}
                  onClick={tabsHandler}
                  setActivePage={setActiveButton}
                  setActiveTab={setActiveTab}
                  pageName='cartoonsActiveTab'
                  defaultClasses='justify-content-start'
                />
                {!showLoader ? (
                  <div
                    className={`${classes.gap_20} justify-content-start d-flex w-100 flex-wrap`}
                  >
                    {data?.length > 0 ? (
                      data.map((item, index) => {
                        return (
                          <CartoonCard
                            cartoon={item}
                            key={item._id}
                            categories={cartoonCategories && cartoonCategories}
                            languageKey={ctx.language}
                            userCartoonsData={data}
                            id={id}
                            setDisabled={() => {}}
                            uploadedCartoon={uploadedCartoon}
                            deleteCartoon={(cartoonId) =>
                              deleteCartoonHandler(cartoonId, id)
                            }
                          />
                        );
                      })
                    ) : (
                      <div className='w-100 d-flex justify-content-center'>
                        <NoData title={ctx.data?.no_data[ctx.language]} />
                      </div>
                    )}
                  </div>
                ) : (
                  <Loader height={true} />
                )}
              </div>
              {pageCount > 1 && !showLoader && (
                <PageinationData
                  pageName='myCartoonsActivePage'
                  activePage={activeButton}
                  setActivePage={setActiveButton}
                  pagesCount={pageCount}
                />
              )}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UserCartoons;
