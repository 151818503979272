import React, { useContext } from 'react';
import styles from './Arrows.module.css';
import { CarouselContext } from '../../Carousel';

const Arrows = () => {
  const { changeSlide } = useContext(CarouselContext);

  return (
      <div className={`${styles.arrows} position-absolute d-flex justify-content-between align-items-center h-100 w-100`}>
        <div className={`${styles.arrow} d-flex align-items-center justify-content-center`} onClick={() => changeSlide(-1)}>
          <img
            src='/assets/slider/home/sliderPrevButton.svg'
            alt=''
            className='w-100 h-100'
          />
        </div>
        <div className={styles.arrow} onClick={() => changeSlide(1)}>
          <img
            src='/assets/slider/home/sliderNextButton.svg'
            alt=''
            className='w-100 h-100'
          />
        </div>
      </div>
  );
}

export default Arrows
