import classes from './Home.module.css';
import Category from './category/Category';
import Contacts from './contacts/Contacts';
import React, { useEffect, useState } from 'react';
import Suggestions from './suggestions/Suggestions';
import Bestsellers from './bestsellers/Bestsellers';
import { useDispatch, useSelector } from 'react-redux';
import LatestArticles from './latestArticles/LatestArticles';
import Carousel from '../../components/customCarousel/Carousel';
import Loader from '../../components/spinner/Loader';
import bestsellers from '../../store/bestsellers/Bestsellers';
import productCategories from '../../store/category/ProductCategories';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';

const Home = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const bestsellersData = useSelector((state) => state.bestsellers.bestsellers);
  const [delay, setDelay] = useState(false)
  
  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Home_page_visited')
    dispatch(productCategories());
    dispatch(bestsellers(4, 1));
  }, [dispatch]);

  if (categories?.length === 0 || !bestsellersData) {
    return <Loader />;
  }

  setTimeout(() => {
    setDelay(true)
  }, 200);

  return (
    <div className={`${classes.home_page_container} d-flex w-100 align-items-center justify-content-center flex-wrap`}>
      <Carousel />
      {delay &&
        <>
          <Category categories={categories} />
          <Bestsellers bestsellers={bestsellersData} categories={categories} />
          <Contacts />
          <LatestArticles />
          <Suggestions />
        </>
      }
    </div>
  );
};

export default Home;
