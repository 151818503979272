import classes from './PlayListImg.module.css';
import React, { useState } from 'react';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { Link } from 'react-router-dom';

const PlaylistImg = (props) => {
  const { singlePlayList, id } = props;
  const [isHover, setIsHover] = useState(false);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <Link
            to={singlePlayList[0] && `/cartoons/${id}`}
            state={{ play: true }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className={`d-flex justify-content-center w-100 ${classes.img_container}`}
          >
            <div className={`d-flex align-items-center`}>
              <img
                className={'w-100'}
                src={singlePlayList[0]?.imageUrl ? `${process.env.REACT_APP_DEV_URI}${singlePlayList[0]?.imageUrl}` : ''}
                alt=''
              />
              {isHover && singlePlayList[0]?.imageUrl && (
                <div
                  className={`position-absolute justify-content-center h-100 ${classes.cover_container} ${classes.width_360} w-100 ${classes.play_all}`}
                >
                  <div
                    className={`${classes.gap_5} d-flex justify-content-center w-100 align-items-center h-100`}
                  >
                    <img
                      src='../../../assets/video/play.svg'
                      alt=''
                      className={classes.fill_white}
                    />
                    <span className={`${classes.play_all_text}`}>
                      {ctx.data?.play_all[ctx.language]}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </Link>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PlaylistImg;
