import { useDispatch } from "react-redux";
import classes from "./Profile.module.css";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../../context/LanguageContext";
import { constantData } from "../../../../utils/helpers/constantData";
import { changeTextShape } from "../../../../utils/helpers/changeTextShape";
import { allPlayListsAction } from "../../../../store/cartoons/allPlaylist/AllPlayListsSlice";

const Profile = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [scrollToContact, setScrollToContact] = useState(false);
  const {
    setShow,
    setProfileParametrsShow,
    profileParametrsShow,
    setConfirmLogOut,
    authToken,
  } = props;

  const logIn = () => {
    if (authToken) {
      setProfileParametrsShow(true);
    } else {
      setShow(true);
      setProfileParametrsShow(false);
    }
  };

  const handleClick = () => {
    navigate("/");
    setScrollToContact(true);
  };

  useEffect(() => {
    if (window.location.pathname === "/" && scrollToContact) {
      setTimeout(() => {
        let item = document.getElementById("contact-us");
        if (item) {
          item?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        setScrollToContact(false);
      }, 300);
      return () => clearTimeout();
    }
  }, [scrollToContact, location]);

  const closeDropdownHandler = (key) => () => {
    if (key.includes('my-playlists')) {
      dispatch(allPlayListsAction.resetPlaylist())
    }
    setProfileParametrsShow(false)
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.profile_parametrs_container} h-100 d-flex align-items-center`}
           >
            <div
              className={`${classes.profile_icon_container} border-0 `}
              onClick={() => {
                logIn();
              }}
              type="button"
            >
              <img
                className="w-100"
                src="../../assets/icons/navBarIcons/profile.svg"
                alt=""
              />
            </div>
            <div
              className={`overflow-hidden w-100 position-absolute ${profileParametrsShow ? `${classes.profile_data} ${classes.profile_animated}` : `${classes.profile_data}`}`}
             >
              <div className={`${classes.profile_dropdown_visible} w-100 h-100 position-absolute h-100 bg-transparent`}></div>
                <div
                  className={`${classes.profile_parametrs} ${classes.line} ${classes.height_273} d-flex flex-wrap w-100 align-items-center align-items-center`}
                 >
                  {constantData.menus.map((item, index) => {
                    return (
                      <Link
                        className={`${classes.link} d-flex w-100 font-weight-normal text-decoration-none`}
                        to={changeTextShape.toKebabcase(ctx.data && ctx.data[item], "en")}
                        onClick={closeDropdownHandler(changeTextShape.toKebabcase(ctx.data && ctx.data[item], "en"))}
                        key={`profile_${index}`}
                        state={{ path: "profile" }}
                      >
                        {changeTextShape.fisrtCharackterToUpperCase(
                          ctx.data && ctx.data[item],
                          ctx.language
                        )}
                      </Link>
                    );
                  })}
                  <a
                    className={`${classes.link} d-flex w-100 font-weight-normal text-decoration-none`}
                    href={`${process.env.REACT_APP_DEV_URI}/privacy?lang=${
                      ctx.language === "am" ? "hy" : ctx.language
                    }`}
                    onClick={() => setProfileParametrsShow(false)}
                  >
                    {changeTextShape.fisrtCharackterToUpperCase(
                      ctx.data?.privacy_policy,
                      ctx.language
                    )}
                  </a>
                  <button
                    onClick={handleClick}
                    className={`${classes.link} d-flex bg-transparent h-100 w-100 border-0`}
                  >
                    {changeTextShape.fisrtCharackterToUpperCase(
                      ctx.data?.contact_us,
                      ctx.language
                    )}
                  </button>
                </div>
                
                <div className={`${classes.profile_parametrs} ${classes.padding_15} d-flex align-items-center`}>
                  <button
                    className={`${classes.link} d-flex bg-transparent h-100 w-100 border-0`}
                    onClick={() => {
                      setConfirmLogOut(true);
                      setProfileParametrsShow(false);
                    }}
                  >
                    {changeTextShape.fisrtCharackterToUpperCase(
                      ctx.data?.log_out,
                      ctx.language
                    )}
                  </button>
                </div>
              </div>
            </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Profile;
