import Modals from '../Modals';
import Cookies from 'js-cookie';
import ReactDOM from 'react-dom';
import SignIn from './signIn/SignIn';
import Loader from '../../spinner/Loader';
import { useDispatch } from 'react-redux';
import 'react-phone-input-2/lib/style.css';
import ErrorModal from '../error/ErrorModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import login from '../../../store/login/Login';
import styles from './SignInSettings.module.css';
import { auth } from '../../../firebase/FirebaseConfig';
import React, { useEffect, useState } from 'react';
import CodeVerification from './codeVerification/CodeVerification';
import { LanguageContext } from '../../../context/LanguageContext';
import { verifyotp } from '../../../utils/helpers/verifyOTP/verifyOTP';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { requestotp } from '../../../utils/helpers/requestOTP/requestOTP';
import { operationsWithKeys } from '../../../utils/helpers/operationsWithKeys/operationsWithKeys';
import { operationsWithPhoneNumber } from '../../../utils/helpers/operationsWithPhoneNumber/operationsWithPhoneNumber';

const SignInSettings = (props) => {
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState([]);
  const [index, setIndex] = useState(0);
  const token = Cookies.get('auth_token');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
  const prevButton = document.getElementById('prevButton');
  const [codeVerification, setCodeVerification] = useState(true);
  const [recaptchaId, setRecaptchaId] = useState('recaptchaId');
  const curInput = document.getElementById(`auth_${index + 1}`);
  const nextInput = document.getElementById(`auth_${index + 2}`);
  const { setShow, showAuth, onPress, onHide, setShowAuth, country, setCountry} = props;
  const [disabled, setDisabled] = useState(['signIn', 'codeVerification']);
  const [providerError, setProviderError] = useState({status: false, message: ''});
  const number = operationsWithPhoneNumber.particularlyHideNumber(phoneNumber, showModal.includes('validated'));

  useEffect(() => {
    if (showAuth && !showModal.includes('validated')) {
      setShowModal(['settings']);
    }
  }, [showAuth]);

  const clickHandler = (id) => {
    setIndex(id);
  };

  const request = (e) => {
    e.preventDefault();
    requestotp.requestOTP(
      e,
      operationsWithPhoneNumber.generateRecaptcha,
      recaptchaId,
      RecaptchaVerifier,
      auth,
      signInWithPhoneNumber,
      phoneNumber,
      setShowModal,
      setShowAuth,
      true,
      setError,
      setCodeVerification,
      setIsLoading,
      disabled,
      setDisabled
    );
  };

  const loginByPhoneNumber = async (
    confirmationResult,
    verificationCode,
    name,
    lang
  ) => {
    try {
      const result = await confirmationResult.confirm(verificationCode);
      const response = await dispatch(login(result.user.accessToken));

      if (response && response.token.length > 0) {
        setCodeVerification(true);
        setShowModal([]);
        setIsLoading([]);
        setShow(false);
        window.location.reload();
      }
    } catch (e) {
      setIsLoading([]);
      setCodeVerification(false);
      setDisabled([]);
    }
  };

  useEffect(() => {
    if (
      showModal.includes('validated') &&
      OTP &&
      !OTP.includes('') &&
      OTP.length === 6
    ) {
      const filteredKey = disabled.filter((item) => item !== 'signIn');
      setDisabled([filteredKey]);
    } else {
      if (!disabled.includes('codeVerification')) {
        setDisabled(prev => [...prev, 'codeVerification']);
      }
    }
  }, [OTP, showModal]);

  const phoneNumberChangeHandler = (phone) => {
    setPhoneNumber(`+${phone}`);
  };

  prevButton?.addEventListener('click', () => {
    setShowModal(['settings']);
    setShow(true);
    setIsLoading([]);
    setDisabled(['codeVerification']);
    setCodeVerification(true);
    setOTP([]);
  });

  useEffect(() => {
    if (showModal.includes('validated') && !disabled.includes('resend')) {
      const timer = setTimeout(() => {
        setShowAuth(false);
        setShowModal([]);
        setError(true);
      }, 40000);
      return () => clearTimeout(timer);
    }
  }, [showModal, setShowAuth, disabled]);

  const hideModal = (name) => {
    if (name === 'settings') {
      onHide();
      setShowModal([]);
    } else if (name === 'validated') {
      setShow(false);
      setOTP([]);
      setCodeVerification(true);
      setShowModal([]);
    } else if (name === 'error') {
      setError(false);
      setShowModal(['settings']);
      setDisabled([]);
    }
    setProviderError({ status: false });
    setIsLoading([]);
  };

  useEffect(() => {
    if (token) {
      sessionStorage.removeItem('stateStatus');
    }
  }, [token]);


  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div id={recaptchaId} />
            <Modals
              show={showModal.includes('settings')}
              onHide={() => hideModal('settings')}
            >
              <SignIn
              country={country}
              setCountry={setCountry}
                showAuth={showModal.includes('settings')}
                setDisabled={setDisabled}
                requestOTP={request}
                phoneNumber={phoneNumber}
                disabled={disabled}
                onPress={onPress}
                onPhoneChange={(phone) => phoneNumberChangeHandler(phone)}
                recaptchaId={recaptchaId}
                setPhoneNumber={setPhoneNumber}
                isLoading={isLoading}
                setShow={setShow}
                error={providerError}
                setError={setProviderError}
                letsStart={true}
              />
            </Modals>
            <Modals
              login={'true'}
              show={showModal.includes('validated')}
              onHide={() => hideModal('validated')}
              image='../assets/icons/prevIcon/prevIcon.svg'
            >
              <CodeVerification
                login={true}
                type='auth'
                disabled={disabled}
                phoneNumber={number}
                onSubmit={(e) =>
                  verifyotp.verifyOTP(
                    e,
                    'codeVerification',
                    OTP,
                    setDisabled,
                    setIsLoading,
                    loginByPhoneNumber,
                    false,
                    true,
                    ctx.data,
                    ctx.language
                  )
                }
                onChange={(e, id) => operationsWithPhoneNumber.changeCode(e, id, OTP, index, setOTP)}
                onFocus={clickHandler}
                onKeyDown={(e) =>
                  operationsWithKeys.keyDownHandler(
                    e,
                    setCodeVerification,
                    curInput,
                    index,
                    OTP,
                    setOTP,
                    'auth'
                  )
                }
                onKeyUp={() => operationsWithKeys.keyUpHandler(OTP, setOTP, curInput, nextInput)}
                codeVerification={codeVerification}
                resendCode={(e) =>
                  operationsWithPhoneNumber.resendCode(
                    e,
                    setRecaptchaId,
                    recaptchaId,
                    ReactDOM,
                    request,
                    setDisabled,
                    disabled
                  )
                }
                onDoubleClick={() => setDisabled(['resend'])}
              />
            </Modals>

            <ErrorModal
              show={providerError.status ? providerError.status : error}
              onHide={() => hideModal('error')}
              errorMessage={
                providerError.message !== ''
                  ? providerError.message
                  : ctx.data?.your_session_expired[ctx.language]
              }
            />
            {isLoading.includes('validation') && (
              <div
                className={`position-fixed d-flex align-items-center justify-content-center ${styles.wait_while_is_authorizing}`}
              >
                <Loader />
              </div>
            )}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default SignInSettings;
