import React, { useState } from 'react';
import classes from './DarkMode.module.css';
import { LanguageContext } from '../../../../../../context/LanguageContext';
import Button from '../../../../../../components/buttons/authButton/Button'

const DarkMode = (props) => {
  const { brightnessModeHandler} = props;
  const [brightnessMode, setBrightnessMode] = useState(window.sessionStorage.getItem('appearance') ?? 'default')

  const brightnessHandler = (type) => {
    setBrightnessMode(type)
    brightnessModeHandler(type)
    window.sessionStorage.setItem('appearance', type);
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.padding_5} ${classes.height_30} w-100`}
          >
            <div className={`${classes.brightness_container} d-flex w-100 justify-content-around`}>
              {['default', 'sepia', 'darck'].map((item) => {
                return (
                  <Button
                    key={item}
                    divStyle={classes.darck_mode}
                    buttonStyle={`border-0 bg-transparent ${brightnessMode === item ? classes.active_button : ''}`}
                    darkMode={brightnessMode}
                    onClick={() => brightnessHandler(item)}
                    text={ctx.data[item === 'darck' ? 'night' : item][ctx.language].charAt(0).toUpperCase() +
                    ctx.data[item === 'darck' ? 'night' : item][ctx.language].slice(1)}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default DarkMode;
