import React, { useEffect, useMemo, useState } from 'react';
import UploadCartoonsItem from '../UploadCartoonsItem';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { inputIdentifier } from '../../../../../utils/inputIdentifier';
import Loader from '../../../../../components/spinner/Loader';

const UploadCartoonName = (props) => {
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState('am');
  const { uploadNameLanguage, name, containsError, cartoonData, cartoonId} = props;

  const changeLanguage = (type) => {
    setLanguage(type);
  };
  const languageData = useMemo(() => process.env.REACT_APP_LANGUAGES.split(', '),[]);
  const textInputProps = inputIdentifier.inputIdentifierHandler(languageData, 'name', 'label');

  useEffect(() => {
    languageData.forEach((item) => {
      const selectedLanguage = document.getElementById(
        textInputProps.inputIds[item]
      );
      if (selectedLanguage) {
        if (containsError) {
          if ((name?.item && name?.item !== '') || language === item) {
            selectedLanguage.style.color = '#09BCC1';
          } else if (name[item] && name?.item !== '' && language !== item) {
            selectedLanguage.style.color = 'black';
          } else if (language !== item && (!name.item || name?.item === '')) {
            selectedLanguage.style.color = '#D62626';
          }
        } else {
          if ((name?.item && name?.item !== '') || language === item) {
            selectedLanguage.style.color = '#09BCC1';
          } else {
            selectedLanguage.style.color = 'black';
          }
        }
      }
    });
  }, [containsError, language, name, languageData, textInputProps]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <UploadCartoonsItem
            keys='title'
            changeLanguage={(type) => changeLanguage(type)}
            languageHandler={uploadNameLanguage}
            selectedLanguage={language}
            name={name}
            setShow={setShow}
            show={show}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            placeholder={ctx.data?.cartoon_name[ctx.language]}
            title={ctx.data?.cartoon_name[ctx.language]}
            textInputProps={textInputProps}
            cartoonData={cartoonData}
            cartoonId={cartoonId}
          />
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonName;
