import React from 'react';
import classes from './VolumeProgressBar.module.css'
import { videoPlayer } from '../../../../utils/helpers/videoPlayer';

const VolumeProgressBar = (props) => {
  const { iconUrl, video, volumeMoved, setMuteOrUnmute, changeVolumeOnClick, setVolumeMouved, iconUrls, setIsMute, setVolume, setCurtime, setDuration } = props

  const handleMapMove = (e) => {
    videoPlayer.onVolumeMouseoMove(e, true, video, iconUrls, setIsMute, setVolume)
    videoPlayer.curentTimeHandler(video, setCurtime, setDuration)
};

  return (
    <div className='d-flex' onMouseOver={videoPlayer.volumeOver} onMouseOut={() => videoPlayer.volumeOverOut(volumeMoved)} onTouchStart={videoPlayer.volumeOver}>
    <div className={`${classes.volume}`} onClick={(event) => setMuteOrUnmute(event)} onMouseOver={videoPlayer.volumeOver} onTouchStart={videoPlayer.volumeOver} >
      <img src={iconUrl} alt='volume' className={`${classes.player_icon} ${classes.fill_white} ${classes.volume_img} object-fit-cover`} id='volume' />
    </div>
    <div
      className={`${classes.volume_progress_img} d-flex ${classes.margin_auto_0} ${classes.relative} ${classes.cursor_pointer} ${classes.width_0}  position-relative`}
      id='volume_progress_main'
      onMouseOver={videoPlayer.volumeOver}
      onClick={(event) => changeVolumeOnClick(event)}
      onMouseUp={() => setVolumeMouved(false)}
      onMouseMove={(event) => videoPlayer.onVolumeMouseoMove(event, volumeMoved, video, iconUrls, setIsMute, setVolume)}
      onMouseOut={() => videoPlayer.volumeOverOut(volumeMoved)}
      onTouchStart={videoPlayer.volumeOver}
      onTouchMove={handleMapMove}
    >
      <div className={`progress ${classes.volume_progressa}`} id='volume_duration'>
        <div className='progress-bar' role='progressbar' id='volume_progresss' aria-valuemin='0' aria-valuemax='100'></div>
      </div>
      <div
        className={`thumb`}
        id='volume_thumb'
        onMouseDown={() => setVolumeMouved(true)}
      />

    </div>
  </div> 
  );
};

export default VolumeProgressBar;