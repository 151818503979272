import { useDispatch } from 'react-redux';
import classes from './UserCredentials.module.css';
import PhoneNumber from '../phoneNumber/PhoneNumber';
import React, { useContext, useEffect, useState } from 'react';
import editEmail from '../../../../store/edit/editEmail/EditEmail';
import { UserContext } from '../../../../context/user/UserContext';
import ErrorMessage from '../../../../components/error/ErrorMessage';
import { LanguageContext } from '../../../../context/LanguageContext';
import editUserData from '../../../../store/edit/editUserData/EditUserData';
import EditUserEmailModals from '../editUserEmailModals/EditUserEmailModals';
import codeValidation from '../../../../store/edit/codeValidation/CodeValidation';
import ChangeUserData from '../../../../components/user/editData/ChangeUserData';
import { editEmailFunctions } from '../../../../utils/helpers/editEmail/editEmail';
import UserAccountComponent from '../../../../components/user/userAccountComponent/UserAccountComponent';
import UserAccountComponentData from '../../../../components/user/userAccountComponent/userAccountComponentData/UserAccountComponentData';
import { operationsWithKeys } from '../../../../utils/helpers/operationsWithKeys/operationsWithKeys';
import { operationsWithPhoneNumber } from '../../../../utils/helpers/operationsWithPhoneNumber/operationsWithPhoneNumber';
import { constantData } from '../../../../utils/helpers/constantData';

const UserCredentials = (props) => {
  const {
    data,
    setData,
    requestOTP,
    phoneNumberChangeHandler
  } = props;
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState([]);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(true);
  const [changeData, setChangeData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { user, setUserData } = useContext(UserContext);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [confIsVisible, setconfIsVisible] = useState(false);
  const [codeVerification, setCodeVerification] = useState(true);
  const curInput = document.getElementById(`email_${index + 1}`);
  const nextInput = document.getElementById(`email_${index + 2}`);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [codeDisabled, setCodeDisabled] = useState(['codeVerification']);
  const isValidEmail = () => editEmailFunctions.isValidEmail(changeData.email);
  const [errorEditData, setErrorEditData] = useState({type: null, message:''})
  const {userData} = constantData

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setChangeData({
        displayName: user.displayName,
        username: user.username,
        email: user.email,
      });
    }
  }, [user]);

  const clickHandler = (id) => {
    setIndex(id);
  };

  const changeUserData = (type) => e =>{
    const mutableData = { ...changeData };
    mutableData[type] = e.target.value;
    setErrorEditData({type: null, message:''})
    setChangeData(mutableData);
  };

  const userDataHandler = (type, data, lang) => async (e) => {
    e.preventDefault();
    let mutableUser = { ...user };
    const name =
      type === 'displayName'
        ? { displayName: changeData[type], username: user['username'] }
        : { displayName: user['displayName'], username: changeData[type] };
    if (type === 'displayName' || type === 'username') {
      const response = await dispatch(editUserData(name.displayName, name.username));
        if (response.payload?.editedData.status === 200) {
          mutableUser[type] = changeData[type];
          setUserData(mutableUser);
          setData([]);
        } else if (response.response.status === 400) {
          setErrorEditData({type:type, message: data['wrong_username'][lang]})
        }

    } else if (type === 'email') {
      if (changeData[type] && changeData[type] !== user.email && isValidEmail(changeData[type])) {
        const response = await dispatch(editEmail(changeData[type]));
        if (response.payload.editEmail.status === 200) {
          setconfIsVisible(true);
          setCodeDisabled(['codeVerification']);
          setData([]);
        } else {
          setError(true);
          setconfIsVisible(false);
          setErrorMessage(data.existing_email[lang]);
        }
      } else if (!isValidEmail(changeData[type])) {
        setError(true);
        setconfIsVisible(false);
        setErrorMessage(data.invalid_email_error_message[lang]);
      } else {
        setInvalidEmail(true);
      }
    } else {
      if (phoneNumber && `+${phoneNumber}` !== user.phoneNumber) {
        requestOTP(e)
        e.preventDefault();
        setInvalidPhoneNumber(false);
      } else {
        setInvalidPhoneNumber(true);
      }
    }
  };

  const resendCode = async () => {
    OTP.length === 4 && !OTP.includes('')
      ? setCodeDisabled(['resend'])
      : setCodeDisabled(['codeVerification', 'resend']);
    const response = await dispatch(editEmail(changeData['email']));
    if (response) {
      OTP.length === 4 && !OTP.includes('')
        ? setCodeDisabled([])
        : setCodeDisabled(['codeVerification']);
    }
  };
  
  useEffect(() => {
    editEmailFunctions.checkOTPCode(confIsVisible, OTP, setActiveButton);
  }, [OTP, confIsVisible]);

  useEffect(() => {
    if (OTP.length === 4 && !OTP.includes('')) {
      setCodeDisabled([]);
    } else {
      setCodeDisabled(['codeVerification']);
    }
  }, [OTP]);

  const codeVerificationOnHide = () => {
      setconfIsVisible(false);
      setChangeData({})

      setOTP([])
      setCodeVerification(true)
      setCodeDisabled(['codeVerification'])
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            {Object.keys(userData).map((item, index) => {
              return (
                <div
                  className={`justify-content-center d-flex w-100 flex-wrap`}
                  key={`${item}_${index}`}
                >
                  {!data.includes(item) ? (
                    <UserAccountComponent onClick={() =>{setData([item]); setErrorEditData({type: null, message:''})}}>
                      <UserAccountComponentData
                        title={
                          user[item]
                            ? user[item]
                            : ctx.data && ctx.data[userData[item]][ctx.language]
                        }
                        user={user[item]}
                        imgUrl={`../assets/userAccount/${item}.svg`}
                      />
                    </UserAccountComponent>
                  ) : (
                    <div
                      className={`justify-content-center d-flex w-100`}
                    >
                      {item !== 'phoneNumber' ? (
                        <div className={`justify-content-center flex-wrap d-flex w-100`}>
                        <ChangeUserData
                          cancel={() => setData([])}
                          onSubmit={userDataHandler(item, ctx.data, ctx.language)}
                          label={
                            ctx.data &&
                            ctx.language &&
                            ctx.data[userData[item]][ctx.language]
                          }
                          type={'text'}
                          placeholder={ctx.data?.change_name[ctx.language]}
                          defaultValue={user[item] ?? ''}
                          onChange={changeUserData(item)}
                          error={errorEditData.type === item && <ErrorMessage text={errorEditData.message} className={classes.padding_top_5}/>}
                        />
                        </div>
                      ) : (
                        <PhoneNumber
                          user={user}
                          onPhoneChange={(phone) =>
                            phoneNumberChangeHandler(phone)
                          }
                          phoneNumber={user.phoneNumber}
                          addId={true}
                          cancel={() => setData([])}
                          handleSubmit={userDataHandler()}
                          setPhoneNumber={setPhoneNumber}
                          setInvalidPhoneNumber={setInvalidPhoneNumber}
                          invalidPhoneNumber={invalidPhoneNumber}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
            <EditUserEmailModals
              confIsVisible={confIsVisible}
              codeDisabled={codeDisabled}
              email={changeData.email}
              clickHandler={clickHandler}
              verifyOTP={(e) => editEmailFunctions.verifyOTP(e, user, codeVerification, confIsVisible, setStatus,
                 OTP, dispatch, codeValidation, setconfIsVisible, setCodeVerification, changeData, setUserData
                )
              }
              changeCode={(e, id) => operationsWithPhoneNumber.changeCode(e, id, OTP, index, setOTP)}
              keyDownHandler={(e) =>
                operationsWithKeys.keyDownHandler(e, setCodeVerification, curInput, index, OTP, setOTP, 'email')
              }
              keyUpHandler={() =>
                operationsWithKeys.keyUpHandler(OTP, setOTP, curInput, nextInput)
              }
              codeVerification={codeVerification}
              activeButton={activeButton}
              resendCode={resendCode}
              setconfIsVisible={setconfIsVisible}
              index={index}
              error={error}
              invalidEmail={invalidEmail}
              setError={setError}
              setInvalidEmail={setInvalidEmail}
              errorMessage={errorMessage}
              status={status}
              setChangeData={setChangeData}
              codeVerificationOnHide={codeVerificationOnHide}
            />
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UserCredentials;
