import React, { useEffect, useState } from 'react';
import classes from './UploadCartoonTag.module.css';
import ErrorMessage from '../../../../components/error/ErrorMessage';
import { LanguageContext } from '../../../../context/LanguageContext';
import UploadCartoonInput from '../uploadCartoonsItem/uploadCartoonInput/UploadCartoonInput';
import UploadCartoonTitle from '../uploadCartoonsItem/uploadCartoonTitle/UploadCartoonTitle';

const UploadCartoonTag = (props) => {
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const { hashTagData, onChange, deleteTag, containsError} = props; 

  useEffect(() => {
    if (containsError || (hashTagData.length === 1 && hashTagData[0] === '' && containsError)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [containsError, hashTagData]);  

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.gap_10} ${classes.tag_container} ${classes.max_height_90} d-flex flex-wrap w-100`}
          >
            <div
              className={`${classes.hash_tag_container} w-100 flex-wrap justify-content-start position-relative d-flex d-flex align-items-center w-100`}
            >
              <UploadCartoonTitle
                setShow={setShow}
                show={show}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                title={ctx.data?.hashtag[ctx.language]}
              />

              <div
                className={`${classes.tag_container} w-100 flex-wrap d-flex`}
              >
                <UploadCartoonInput
                  placeholder={`#${ctx.data?.hashtag[ctx.language]}`}
                  title={`#${ctx.data?.hashtag[ctx.language]}`}
                  onChange={onChange}
                  type='text'
                  defaultValue={hashTagData}
                  id={'tag_input'}
                />
                {isError && (
                  <ErrorMessage text={ctx.data?.error_meesage[ctx.language]} />
                )}
              </div>
            </div>
            <div className={`${classes.gap_10} w-100 d-flex flex-wrap`}>
              {hashTagData?.length > 0 &&
                hashTagData.map((tag, index) => {
                  return (
                    <React.Fragment key={`${tag}_${index}_${tag.length}`}>
                    {tag !== '' ? <div
                      className={`${classes.hash_tag_item_container} ${classes.padding_5} d-flex align-items-center justify-content-around`}
                    >
                      <div>
                        <span className='text-center'>{`#${tag}`}</span>
                      </div>
                      <div
                        className={`${classes.hash_tag_img_container}`}
                        onClick={() => deleteTag(index)}
                      >
                        <img
                          className='w-100'
                          src='../assets/icons/cancel/cancelModal.svg'
                          alt=''
                        />
                      </div>
                    </div> : <></>}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonTag;
