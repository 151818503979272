import { Pagination } from 'react-bootstrap';
import classes from './Pageination.module.css'
import React, { useCallback, useEffect, useState } from 'react';

const PageinationData = (props) => {
  const [pages, setPages] = useState([]);
  const { pageName, activePage,  setActivePage, pagesCount, myBook} = props;
  
  sessionStorage.setItem(pageName, activePage);

  useEffect(() => {
        window.scroll(0, 0)
  }, [activePage])

  const pageHandller = useCallback((id) => {
    setActivePage(id);
  }, [setActivePage]);

  useEffect(() => {
    if(pagesCount <= 5) {
      const pagesItems = [];
      for (
        let pageNumber = 2;
        pageNumber <= (pagesCount < 4 ? pagesCount - 1 : pagesCount === 4 ? 3 : 4);
        pageNumber++
      ) {
        pagesItems.push(
          <Pagination.Item
            key={`pagination_${pageNumber}`}
            onClick={() => pageHandller(pageNumber)}
            active={pageNumber === activePage}
          >
            {pageNumber}
          </Pagination.Item>
        );
      }
      setPages(pagesItems);
    } else if (activePage <= 3) {
      const pagesItems = [];
      for (
        let pageNumber = 2;
        pageNumber <= (pagesCount < 4 ? pagesCount - 1 : pagesCount === 4 ? 3 : 4);
        pageNumber++
      ) {
        pagesItems.push(
          <Pagination.Item
            key={`pagination_${pageNumber}`}
            onClick={() => pageHandller(pageNumber)}
            active={pageNumber === activePage}
          >
            {pageNumber}
          </Pagination.Item>
        );
      }
      pagesCount > 4 && pagesItems.push(<Pagination.Ellipsis />);
      setPages(pagesItems);
    } else if (activePage > 3 && activePage <= pagesCount - 3) {
      const pagesItems = [];
      pagesItems.push(<Pagination.Ellipsis />);
      for (
        let pageNumber = activePage - 1;
        pageNumber <= activePage + 1;
        pageNumber++
      ) {
        pagesItems.push(
          <Pagination.Item
            key={`pagination_${pageNumber}`}
            onClick={() => pageHandller(pageNumber)}
            active={pageNumber === activePage}
          >
            {pageNumber}
          </Pagination.Item>
        );
      }
      pagesItems.push(<Pagination.Ellipsis />);
      setPages(pagesItems);
    } else if (pagesCount !== 4)  {
      const pagesItems = [];
      if (pagesCount !== 4) pagesItems.push(<Pagination.Ellipsis />);
      for (
        let pageNumber = pagesCount - 3;
        pageNumber < pagesCount;
        pageNumber++
      ) {
        pagesItems.push(
          <Pagination.Item
            key={`pagination_${pageNumber}`}
            onClick={() => pageHandller(pageNumber)}
            active={pageNumber === activePage}
          >
            {pageNumber}
          </Pagination.Item>
        );
      }
      setPages(pagesItems);
    } else {
      const pagesItems = [];
      for (
        let pageNumber = pagesCount - 2;
        pageNumber < pagesCount;
        pageNumber++
      ) {
        pagesItems.push(
          <Pagination.Item
            key={`pagination_${pageNumber}`}
            onClick={() => pageHandller(pageNumber)}
            active={pageNumber === activePage}
          >
            {pageNumber}
          </Pagination.Item>
        );
      }
      setPages(pagesItems);
    }
  }, [activePage, pagesCount, pageHandller]);

  const next = () => {
    if (activePage < pagesCount) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(pagesCount);
    }
  };

  const prev = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    } else {
      setActivePage(1);
    }
  };



  // const links = document.getElementsByClassName('page-link')
  // if (links) {
  //   for (let link of links) {
  //     Array.from(link.children).forEach(child => {
  //       if (child?.innerText.toString().includes('…')) {
  //         link.classList.add('no-background')
  //       }
  //     })
  // }}

  return (
    <div className={`${classes.pageination_container} d-flex w-100 justify-content-center`}>
      <div
        className={`${classes.pageination_data} ${myBook ? `${classes.pageination_my_books} position-absolute` : ''} d-flex align-items-center justify-content-between`}>
        <Pagination>
          <Pagination.Prev onClick={prev} />
          <Pagination.Item
            onClick={() => pageHandller(1)}
            active={activePage === 1}
          >
            1
          </Pagination.Item>
          {pages}
          <Pagination.Item
            active={activePage === pagesCount}
            onClick={() => pageHandller(pagesCount)}
          >
           {pagesCount}
          </Pagination.Item>
          <Pagination.Next onClick={next} />
        </Pagination>
      </div>
    </div>
  );
};

export default PageinationData;
