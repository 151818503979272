import Cookies from 'js-cookie';
import classes from './SinglePlayList.module.css';
import NoData from '../../../components/noData/NoData';
import { useDispatch, useSelector } from 'react-redux';
import PlayListItems from './playListItems/PlayListItems';
import PageTitle from '../../../components/pageTitle/PageTitle';
import PlaylistDetails from './playlistDetails/PlaylistDetails';
import React, { useCallback, useEffect, useState } from 'react';
import playlist from '../../../store/cartoons/playList/Playlist';
import { LanguageContext } from '../../../context/LanguageContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import SignInErrorModal from '../../../components/modal/error/signInErrorModal/SignInErrorModal';

const SinglePlayList = () => {
  const limit = process.env.REACT_APP_SINGLE_PLAYLIST_LIMIT;
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get('auth_token');
  const [fetched, setFetched] = useState(true);
  const [fatching, setFetching] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [playListData, setPlayListData] = useState([]);
  const [isInitialLoded, setIsInitialLoded] = useState(true);
  const [moreItemsExists, setMoreItemsExists] = useState(false);
  const updatePlayList = useSelector((state) => state.updatePlayList);
  const singlePlayList = useSelector((state) => state.playList.playList);
  const [showErrorModal, setShowErrorModal] = useState(typeof token === 'undefined');

  window.scroll(0, 0);
  localStorage.setItem('firstLoadDone', 1)
  
  const handleData = useCallback(() => {
    if (fatching) {
      return;
    }
    setFetching(true);
    if (isInitialLoded) {
     dispatch(playlist(params.id, limit, activePage));
    }
    setActivePage(activePage + 1);
    if (!isInitialLoded) {
      setIsInitialLoded(true);
    }
    setFetched(true);
  }, [dispatch, params, limit, activePage, fatching, isInitialLoded]);

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Playlist_page')
    if (fetched) {
      setFetched(false);
      if (singlePlayList?.data?.length > 0) {
        setPlayListData(prev => [...prev, ...singlePlayList.data]);
      } else {
        setPlayListData(playListData);
      }
      try {
        if (singlePlayList?.data?.length !== 0) {
          setMoreItemsExists(singlePlayList?.data?.length > 0);
        } else {
          setMoreItemsExists(false);
        }
      } finally {
        setFetching(false);
      }
    }
  }, [singlePlayList]);

  useEffect(() => {
    if (playListData.length === 0 && isInitialLoded) {
      handleData();
    }
  }, [handleData, playListData, isInitialLoded, updatePlayList]);

  useEffect(() => {
    setPlayListData([]);
    setIsInitialLoded(true);
  }, [location]);

  const navigateCartoonPage = () => {
    navigate(`/cartoons/${params.id}/${singlePlayList?.data[0]._id}`);
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
              <div
                className={`${classes.playlistContainer} ${classes.gap_50} d-flex flex-wrap`}
              >
                {!showErrorModal ? (
                  <>
                    <PageTitle title={ctx.data?.my_playlists[ctx.language]} />
                    <div
                      className={`${classes.single_playlist_container} d-flex w-100 justify-content-center`}
                    >
                      <div
                        className={`${classes.gap_50} ${classes.data_container} d-flex w-100 justify-content-center h-100`}
                      >
                        <PlaylistDetails
                          singlePlayList={playListData}
                          navigateCartoonPage={navigateCartoonPage}
                          id={singlePlayList?.data ? `${params?.id}/${singlePlayList?.data[0]?._id}` : ''}
                        />
                        {
                          playListData && playListData?.length > 0 ?
                            <PlayListItems
                              playListData={playListData}
                              loadMore={handleData}
                              moreItemsExists={moreItemsExists}
                              setIsInitialLoded={setIsInitialLoded}
                              // setIsLoading={setIsLoading}
                            /> :
                            <NoData title={ctx.data?.no_cartoons_added_yet[ctx.language]} />
                        }
                      </div>
                    </div>
                  </>
                ) : (
                  <SignInErrorModal
                  country={localStorage.getItem('country')}
                  setShowErrorModal={setShowErrorModal}
                    showErrorModal={showErrorModal}
                    onHide={() => {
                      setShowErrorModal(false);
                      navigate('/');
                    }}
                  />
                )}
              </div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default SinglePlayList;
