import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar } from 'react-bootstrap';
import classes from './UploadCartoons.module.css';
import UploadVideo from './uploadVideo/UploadVideo';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/spinner/Loader';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../../../components/pageTitle/PageTitle';
import { LanguageContext } from '../../../context/LanguageContext';
import UploadCartoonsImage from './uploadImage/UploadCartoonsImage';
import UploadCartoonTag from './uploadCartoonTag/UploadCartoonTag';
import { inputErrorChecker } from '../../../utils/inputErrorChecker';
import fetchCartoon from '../../../store/cartoons/cartoon/FetchCartoon';
import UploadCartoonAuthor from './uploadCartoonAuthor/UploadCartoonAuthor';
import uploadCartoon from '../../../store/cartoons/uploadCartoon/UploadCartoon';
import updateCartoon from '../../../store/cartoons/updateCartoon/UpdateCartoon';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import UploadCartoonLanguage from './uploadCartoonLanguage/UploadCartoonLanguage';
import UploadCartoonName from './uploadCartoonsItem/uploadCartoonName/UploadCartoonName';
import UploadCartoonApplyButton from './uploadCartoonApplyButton/UploadCartoonApplyButton';
import UploadCartoonCategory from './uploadCartoonsItem/uploadCartoonCategory/UploadCartoonCategory';
import UploadCartoonAgeGroup from './uploadCartoonsItem/uploadCartoonAgeGroup/UploadCartoonAgeGroup';
import UploadCartoonDescription from './uploadCartoonsItem/uploadCartoonDescription/UploadCartoonDescription';

const UploadCartoons = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const cancelFileUpload = useRef(null);
  const [apply, setApply] = useState(true);
  const [progress, setProgress] = useState();
  const [videoURL, setVideoURL] = useState();
  const [imageURL, setImageURL] = useState();
  const [tagsValue, setTagsValue] = useState();
  const [updatedData, setUpdateddata] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const cartoonId = new URLSearchParams(location.search).get('cartoonId');
  const keys = { name: 'title', author: 'author', description: 'description' };
  const cartoonData = useSelector((state) => state.cartoonData.cartoonData.cartoonData?.data);
  const [imageSizesAreMatched, setImageSizesAreMatched] = useState(cartoonId ? true : undefined);
  const [lang, setlang] = useState('am')

  const [data, setData] = useState({
    name: {},
    author: {},
    description: {},
    tag: [],
    ageGroup: [],
    language: [],
    category: [],
    video: [],
    image: [],
  });
  const mutableData = { ...data };

  useEffect(() => {
    if (cartoonId && location) {
      dispatch(fetchCartoon(cartoonId));
    }
  }, [dispatch, cartoonId, location]);

  useEffect(() => {
    if (cartoonData && cartoonId) {
      const dataList = { ...data };
      Object.keys(data).forEach((element) => {
        dataList['language'] = [cartoonData['language']]
        dataList[element] = element === 'video' || element === 'image' ? [] : cartoonData[element === 'category' ? 'categories' : element === 'name' ? 'title' : element];
      });
      setVideoURL(`${process.env.REACT_APP_DEV_URI}${cartoonData?.videoUrl}`);
      setImageURL(`${process.env.REACT_APP_DEV_URI}${cartoonData?.imageUrl}`);
      setData(dataList);
    }
  }, [cartoonData, cartoonId]);

  if (progress === 100) {
    setTimeout(() => {
      window.sessionStorage.setItem('cartoonsActiveTab', 0);
      navigate('/my-cartoons');
      window.scroll(0, 0);
    }, 1500);
  }

  const cartoonUploadHandler = ({ lang, name, type }) => e => {
    setlang(lang)
    let mutableUpdatedData = { ...updatedData };
    if (type === 'file' && name === 'video' && e.target.files.length !== 0) {
      mutableData[name] = e.target.files;
      setVideoURL(window.URL.createObjectURL(e.target.files[0]));
      mutableUpdatedData['cartoon'] = e.target.files[0];
    } else if (
      type === 'file' &&
      name === 'image' &&
      e.target.files.length !== 0
    ) {
      const imageURL = window.URL.createObjectURL(e.target.files[0]);
      const img = document.createElement('img');
      img.src = imageURL;
      img.onload = () => {
        if (img.width % 1020 === 0 && img.height % 570 === 0) {
          setImageSizesAreMatched(true);
          setImageURL(imageURL);
          mutableData[name] = e.target.files;
          mutableUpdatedData['image'] = e.target.files[0];
          setErrorMessage(false);
        } else {
          mutableUpdatedData['image'] = [];
          mutableData[name] = [];
          setImageURL();
          setErrorMessage(true);
          setImageSizesAreMatched(false);
        }
      };
    } else if (type === 'checkbox') {
      const value = e.target.checked
        ? [...data[name], e.target.value]
        : JSON.parse(JSON.stringify(data[name])).filter(
            (item) => item !== e.target.value
          );
      mutableData[name] = value;
      mutableUpdatedData['categories'] = value;
    } else if (type === 'radio') {
      mutableData[name] = [e.target.value]
      mutableUpdatedData[name] = [e.target.value]
    } else if (type === 'text') {
      mutableData[name] = e.target.value.split(' ');
      setTagsValue(e.target.value);
      mutableUpdatedData[name] = e.target.value;
    } else {
      mutableData[name] = { ...data[name], [lang]: e.target.value };
      const key = keys[name] && `${keys[name]}${lang.toUpperCase()}`;
      mutableUpdatedData[key] = e.target.value;
    }
    setUpdateddata(mutableUpdatedData);
    setData(mutableData);
  };

  useEffect(() => {
    const notFilledInputs = inputErrorChecker.inputsAreFilled(data, lang);
    setError(notFilledInputs);
  }, [data, lang]);

  useEffect(() => {
    if (!cartoonId) {
      const mustBeFilled = inputErrorChecker.checkDataContainsErrors(
        Object.keys(data).length - 2,
        data,
        Object.keys(data)
      );
      if (
        mustBeFilled.length === 0 &&
        data.image.hasOwnProperty('0') &&
        data.video.hasOwnProperty('0')
      ) {
        setApply(false);
      } else {
        setApply(true);
      }
    } else if (cartoonId && Object.keys(updatedData).length > 0) {
      setApply(false);
    }
  }, [data, updatedData, cartoonId, imageSizesAreMatched]);

  const deleteTag = (index) => {
    const input = document.getElementById('tag_input');
    const filterTag = [...data.tag];
    filterTag.splice(index, 1);
    const value = filterTag.filter((item) => item !== tagsValue);
    input.value = value.join(' ');
    mutableData.tag = filterTag;
    setData(mutableData);
  };

  const onUploadProgress = (data) => {
    setProgress(Math.round((100 * data.loaded) / data.total));
  };

  const applyUploadCartoon = () => {
    if (!cartoonId) {
      dispatch(uploadCartoon(data, onUploadProgress, cancelFileUpload));
      logEvent(firebaseAnalytics, 'Web_Carton_uploaded');
    } else {
      dispatch(
        updateCartoon(
          updatedData,
          onUploadProgress,
          cancelFileUpload,
          cartoonId
        )
      );
      logEvent(firebaseAnalytics, 'Web_Carton_edited');
    }
  };

  const cancelHandler = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current('User has canceled the file upload.');
    navigate('/cartoons');
    window.scroll(0, 0);
  };

  if ((!cartoonData || Object.keys(cartoonData).length === 0) && cartoonId) {
    return <Loader />;
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <PageTitle title={ctx.data?.enter_cartoon[ctx.language]} />
            <div
              className={`${classes.gap_50} ${classes.padding_50_20} w-100 flex-wrap d-flex justify-content-center`}
            >
              <div
                className={`${classes.upload_cartoon_container} align-items-center flex-wrap align-items-center w-100 d-flex justify-content-center`}
              >
                <div className={`${classes.dataContainer} w-100 d-flex`}>
                  <div
                    className={`${classes.upload_cartoon_data_item} ${classes.padding_20} d-flex`}
                  >
                    <div
                      className={`${classes.item_container} ${classes.gap_20} d-flex w-100 flex-wrap`}
                    >
                      <UploadCartoonName
                        cartoonData={data}
                        uploadNameLanguage={(lang) =>
                          cartoonUploadHandler({
                            lang,
                            name: 'name',
                            type: 'multytext',
                          })
                        }
                        cartoonId={cartoonId}
                        name={data.name}
                        containsError={error?.includes('name')}
                      />
                      <UploadCartoonAuthor
                      cartoonId={cartoonId}
                        cartoonData={data}
                        uploadAuthorLanguage={(lang) =>
                          cartoonUploadHandler({
                            lang,
                            name: 'author',
                            type: 'multytext',
                          })
                        }
                        author={data.author}
                        containsError={error?.includes('author')}
                      />

                      <UploadCartoonDescription
                        cartoonData={data}
                        uploadDescriptionLanguage={(lang) =>
                          cartoonUploadHandler({
                            lang,
                            name: 'description',
                            type: 'multytext',
                          })
                        }
                        description={data.description}
                        containsError={error?.includes('description')}
                      />
                      <UploadCartoonTag
                        onChange={cartoonUploadHandler({ name: 'tag', type: 'text' })}
                        hashTagData={data.tag}
                        deleteTag={deleteTag}
                        cartoonId={cartoonId}
                        // defaultValue={data.tag}
                        containsError={error?.includes('tag')}
                      />
                      <UploadCartoonAgeGroup
                        onChange={
                          cartoonUploadHandler({
                            name: 'ageGroup',
                            type: 'radio',
                          })
                        }
                        data={data.ageGroup}
                        type='radio'
                        containsError={error?.includes('ageGroup')}
                      />
                      <UploadCartoonLanguage
                        cartoonData={data}
                        languageText={data?.language}
                        languageDataHandler={
                          cartoonUploadHandler({
                            name: 'language',
                            type: 'radio',
                          })
                        }
                        containsError={error?.includes('language')}
                      />
                      <UploadCartoonCategory
                        categoryData={data.category}
                        onChange={
                          cartoonUploadHandler({
                            name: 'category',
                            type: 'checkbox',
                          })
                        }
                        uploadCartoon={true}
                        containsError={error?.includes('category')}
                      />
                    </div>
                  </div>
                  <div className={classes.border_right}></div>
                  <div
                    className={`${classes.upload_cartoon_video_item} ${classes.padding_20} h-100 position-relative`}
                  >
                    <div className={`d-flex flex-wrap ${classes.gap_20}`}>
                      <UploadVideo
                        onChange={
                          cartoonUploadHandler({
                            name: 'video',
                            type: 'file',
                          })
                        }
                        imageURL={imageURL}
                        cartoonURL={videoURL}
                        errorMessage={errorMessage}
                        imageSizesAreMatched={imageSizesAreMatched}
                      />
                      {videoURL && (
                        <UploadCartoonsImage
                          cartoonImage={imageURL}
                          onChange={
                            cartoonUploadHandler({
                              name: 'image',
                              type: 'file',
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.gap_20} justify-content-center w-100 d-flex flex-wrap`}
                >
                  <div
                    className={`${classes.gap_10} justify-content-center w-100 d-flex flex-wrap`}
                  >
                    <div
                      className={`${classes.progress_bar_container} d-flex w-100 justify-content-end`}
                    >
                      {progress && (
                        <ProgressBar
                          now={progress}
                          label={`${progress}%`}
                          className={classes.progress_bar}
                          style={{ height: '20px' }}
                        />
                      )}
                    </div>
                    {progress === 100 && (
                      <div className={`justify-content-center w-100 d-flex`}>
                        <span className={classes.added_cartoon_text}>
                          {ctx.data?.cartoon_successfully_added[ctx.language]}
                        </span>
                      </div>
                    )}
                  </div>
                  <UploadCartoonApplyButton
                    edit={cartoonId}
                    applyUploadCartoon={applyUploadCartoon}
                    apply={apply}
                    cancelUploadCartoon={cancelHandler}
                  />
                </div>
              </div>
            </div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoons;
